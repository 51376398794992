import { useQueryingUI } from "hooks/UI/useQuerying";
import { useEffect, useState } from "react";

/** Persists data so that it keeps old data until new data is given (the useSDK hook triggers data to undefined if fetching) **/
export const usePersistData = <T>(data: T | undefined, hasErrored: boolean) => {
  const [usedData, setUsedData] = useState<T>();
  useQueryingUI(data, hasErrored);
  useEffect(() => {
    if (data) {
      setUsedData(data);
    }
  }, [data]);

  return usedData;
};
