import { VirtualMeterSDK, VirtualMeterTypeList } from "./VirtualMeter.types";
import { CancelToken } from "axios";
import {
  VirtualMetersApi,
  Configuration,
} from "@solarschools/api-sdk-typescript";

export const fetchVirtualMeterResource = async (
  props: VirtualMeterSDK,
  config: Configuration,
  token: CancelToken
) => {
  const client = new VirtualMetersApi(config);

  switch (props.method) {
    case "listVirtualMeterTypes": {
      const { categories } = props.params;
      const { data } = await client.listTypes(categories, {
        cancelToken: token,
      });
      return data ? (data as VirtualMeterTypeList) : undefined;
    }
    default: {
      throw Error("An invalid VirtualMeters method was encountered");
    }
  }
};
