import { Configuration, DataApi } from "@solarschools/api-sdk-typescript";
import { CancelToken } from "axios";
import {
  DataChildEnergyStats,
  DataEnergyBreakdown,
  SitesEnergyRanking,
  DataSolarStats,
  DataEnergyFeed,
  DataSDK,
} from "./Data.types";

export const fetchDataResource = async (
  props: DataSDK,
  config: Configuration,
  token: CancelToken
) => {
  const client = new DataApi(config);

  switch (props.method) {
    case "getChildEnergyBreakdown": {
      const { resourceId, resourceType, start, type, end, period } =
        props.params;
      const { data } = await client.getChildEnergyBreakdown(
        resourceType,
        resourceId,
        type,
        start,
        period,
        end,
        { cancelToken: token }
      );
      return data ? (data as DataEnergyBreakdown) : undefined;
    }
    case "getChildEnergyStats": {
      const { resourceType, resourceId } = props.params;
      const { data } = await client.getChildEnergyStats(
        resourceType,
        resourceId,
        { cancelToken: token }
      );
      return data ? (data as DataChildEnergyStats) : undefined;
    }
    case "getChildSolarStats": {
      const { resourceType, resourceId } = props.params;
      const { data } = await client.getChildSolarStats(
        resourceType,
        resourceId,
        { cancelToken: token }
      );
      return data ? (data as DataSolarStats) : undefined;
    }
    case "getEnergyFeed": {
      const { resourceId, resourceType, start, end, aggregation, weather } =
        props.params;
      const { data } = await client.getEnergyFeed(
        resourceType,
        resourceId,
        start,
        end,
        aggregation,
        weather,
        true,
        undefined,
        undefined,
        { cancelToken: token }
      );
      return data ? (data as DataEnergyFeed) : undefined;
    }
    case "getEnergyStats": {
      const { resourceType, resourceId, start, end } = props.params;
      const { data } = await client.getEnergyStats(
        resourceType,
        resourceId,
        start,
        end,
        { cancelToken: token }
      );
      return data;
    }
    case "getSitesEnergyRanking": {
      const { resourceType, resourceId, order, averageByPopulation } =
        props.params;
      const { data } = await client.getSitesEnergyRanking(
        resourceType,
        resourceId,
        order,
        averageByPopulation,
        { cancelToken: token }
      );
      return data ? (data as SitesEnergyRanking) : undefined;
    }
    case "getWaterFeed": {
      const { resourceType, resourceId, start, end, aggregation } =
        props.params;
      const { data } = await client.getWaterFeed(
        resourceType,
        resourceId,
        start,
        end,
        aggregation,
        { cancelToken: token }
      );
      return data;
    }
    case "getWaterStats": {
      const { resourceType, resourceId, start, end } = props.params;
      const { data } = await client.getWaterStats(
        resourceType,
        resourceId,
        start,
        end,
        { cancelToken: token }
      );
      return data;
    }
    default: {
      throw Error("An invalid Faults method was encountered");
    }
  }
};
