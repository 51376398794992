import { UsersApi, Configuration } from "@solarschools/api-sdk-typescript";
import { UsersSDK, SearchResultList, UserRoles } from "./Users.types";
import { User } from "providers/StoreProvider";
import { CancelToken } from "axios";

export const fetchUsersResource = async (
  props: UsersSDK,
  config: Configuration,
  token: CancelToken
) => {
  const client = new UsersApi(config);
  switch (props.method) {
    case "searchOrganisationResources": {
      const { term, page, limit } = props.params;
      const { data } = await client.searchOrganisationResources(
        term,
        page,
        limit,
        { cancelToken: token }
      );
      return data ? (data as SearchResultList) : undefined;
    }
    case "getMyRoles": {
      const { resourceType, resourceId, assignable } = props.params;
      const { data } = await client.getMyRoles(
        resourceType,
        resourceId,
        assignable ? "true" : "false",
        { cancelToken: token }
      );
      return data ? (data as UserRoles) : undefined;
    }
    case "get": {
      const { id } = props.params;
      const { data } = await client.get(id, { cancelToken: token });
      return data ? (data as User) : undefined;
    }
    case "getResourceUsers": {
      const {
        resourceType,
        resourceId,
        page,
        limit,
        order,
        orderBy,
        attachmentMode,
      } = props.params;
      const { data } = await client.getResourceUsers(
        resourceType,
        resourceId,
        page,
        limit,
        order,
        orderBy,
        attachmentMode
      );
      return data;
    }
  }
};
