import { ThemeSpinnerProps } from "./ThemeSpinner.types";
import { useStyles } from "./ThemeSpinner.styles";
import React, { FunctionComponent } from "react";
import { PageContainer } from "global.styles";
import Icon from "@material-ui/core/Icon";
import { useThemeAssets } from "hooks";

export const ThemeSpinner: FunctionComponent<ThemeSpinnerProps> = ({
	isFullscreen = false,
	...rest
}) => {
	const classes = useStyles();
	const { loader } = useThemeAssets();

	if (isFullscreen) {
		return (
			<PageContainer>
				<Icon classes={{ root: classes.iconRoot }} {...rest}>
					<img
						className={classes.fullScreenIcon}
						src={loader}
						alt="Logo loading spinner"
					/>
				</Icon>
			</PageContainer>
		);
	}

	return (
		<Icon classes={{ root: classes.iconRoot }} {...rest}>
			<img
				className={classes.imageIcon}
				src={loader}
				alt="Logo loading spinner"
			/>
		</Icon>
	);
};
