import { useUtility } from "providers/UtilityProvider/UtilityProvider.hooks";
import { useEffect, useRef } from "react";

/**
 *
 * @param data Value to determine loading state from
 * @param hasErrored Whether there is an error
 */
export const useQueryingUI = (data: any, hasErrored?: boolean) => {
  const utilityStore = useUtility();
  const threadInit = useRef(false);

  useEffect(() => {
    if (!data) {
      // If loading thread has been initialized and the data errored - remove the loading thread
      if (hasErrored && threadInit.current) {
        utilityStore.loadingThreads--;
        threadInit.current = false;
      }
      // If the data is being fetched and the loading thread hasn't yet been initialized -  add the loading thread
      else if (!threadInit.current) {
        utilityStore.loadingThreads++;
        threadInit.current = true;
      }
    } else {
      // If the data exists and the loading thread has been initialized - remove the loading thread
      if (threadInit.current) {
        utilityStore.loadingThreads--;
        threadInit.current = false;
      }
    }
  }, [data, utilityStore, hasErrored]);

  // cleanup on dismount
  useEffect(() => {
    return () => {
      if (threadInit.current) {
        utilityStore.loadingThreads--;
      }
    };
  }, [utilityStore]);
};
