import { IsValidEnergyTimeWindowProps } from "./EnergyValidation.types";
import { isAfter, parseISO } from "date-fns";
import { DateTime } from "luxon";

export const isValidEnergyTimeWindow = ({
  value,
  time,
  timezone,
  hoursOffset = 2,
}: IsValidEnergyTimeWindowProps) => {
  if (value === null || !time || !timezone) {
    return null;
  }

  const isValidWindow = isAfter(
    parseISO(time),
    parseISO(
      DateTime.local()
        .minus({ hours: hoursOffset })
        .setZone(timezone)
        .toFormat("yyyy-MM-dd HH:mm:ss")
    )
  );

  return isValidWindow ? value : null;
};
