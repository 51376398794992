import { OrganisationSDK, Organisation } from "./Organisations.types";
import { CancelToken } from "axios";
import {
  OrganisationsApi,
  Configuration,
} from "@solarschools/api-sdk-typescript";

export const fetchOrganisationResource = async (
  props: OrganisationSDK,
  config: Configuration,
  token: CancelToken
) => {
  const client = new OrganisationsApi(config);
  switch (props.method) {
    case "getById": {
      const { id } = props.params;
      const { data } = await client.get(id, { cancelToken: token });
      return data ? (data as Organisation) : undefined;
    }
    case "getMapSites": {
      const { id, filter } = props.params;
      const { data } = await client.getMapSites(id, filter, {
        cancelToken: token,
      });
      return data;
    }
    case "getSchemas": {
      const { id } = props.params;
      const { data } = await client.getSchemas(id, { cancelToken: token });
      return data;
    }
    case "getSites": {
      const { id, page, limit, filter } = props.params;
      const { data } = await client.getSites(id, page, limit, filter, {
        cancelToken: token,
      });
      return data;
    }
    case "getSitesSolarReport": {
      const { id, timezone } = props.params;
      const { data } = await client.getSitesSolarReport(id, timezone, {
        cancelToken: token,
      });
      return data;
    }
    case "getSolarCurrentStats": {
      const { id } = props.params;
      const { data } = await client.getSolarCurrentStats(id, {
        cancelToken: token,
      });
      return data;
    }
    case "getSummary": {
      const { id, timezone } = props.params;
      const { data } = await client.getSummary(id, timezone, {
        cancelToken: token,
      });
      return data;
    }
    case "getSiteCustomAttributes": {
      const { id } = props.params;
      const { data } = await client.getSiteCustomAttributes(id, {
        cancelToken: token,
      });
      return data;
    }
  }
};
