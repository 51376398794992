import { SVGProps } from "react";
import * as React from "react";

export const ZeroPositiveLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Group 5919"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 160 80"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 4930" fill="none" d="M0 0h160v80H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 5506" clipPath="url(#a)">
      <path
        data-name="Path 13858"
        d="M136.33 11.924h4.421V7.481A7.462 7.462 0 0 1 148.194 0v11.924H160a7.463 7.463 0 0 1-7.444 7.481h-4.362v4.443a7.462 7.462 0 0 1-7.444 7.481V19.4h-11.863a7.462 7.462 0 0 1 7.444-7.481"
      />
      <path
        data-name="Path 13859"
        d="M14.188 50.576h17.421v8.337H.34A17.335 17.335 0 0 1 4.8 47.302l13.184-14.619H0v-8.338h31.035a21 21 0 0 1-5.7 14.394Z"
      />
      <path
        data-name="Path 13860"
        d="M127.59 36.161v5.536a9.544 9.544 0 0 1-8.667 9.65 9.364 9.364 0 0 1-9.942-9.991 9.532 9.532 0 0 1 9.6-8.711h9.061a8.7 8.7 0 0 0 8.681-8.724.054.054 0 0 0-.054-.054h-17.514a18.4 18.4 0 0 0-18.526 17.945 18.112 18.112 0 0 0 18.24 18.333c9.973-.095 17.858-8.6 17.858-18.622v-5.362a.037.037 0 0 0-.037-.037h-8.661a.036.036 0 0 0-.036.037"
      />
      <path
        data-name="Path 13861"
        d="M97.392 24.145s-.007-.006-.011-.006a10.74 10.74 0 0 0-11.071 6.342v-5.826H75.27v34.569h11.04V41.458a8.853 8.853 0 0 1 .232-1.222c.015-.057.034-.118.053-.179-.017.06-.038.118-.053.179l.231-.637a7.176 7.176 0 0 1 1.143-1.83c2.891-3.043 4.8-3.788 9.562-3.623V24.142h-.087"
      />
      <path
        data-name="Path 13862"
        d="M59.413 38.319c0-4.233-2.68-6.991-6.637-6.991-3.829 0-6.445 2.822-7.147 6.991Zm10.593 5.964H45.821c1.085 4.1 4.02 6.413 8.1 6.413a11.594 11.594 0 0 0 8.232-3.527l5.807 5.708c-3.509 3.848-8.678 6.093-15 6.093-11.231 0-18.251-7.055-18.251-17.573 0-10.711 7.275-17.765 17.932-17.765 12.125 0 18.124 8.016 17.358 20.651"
      />
      <path
        data-name="Path 13863"
        d="M.199 70.875h4.209a5.869 5.869 0 0 1 2.594.55 4.129 4.129 0 0 1 1.755 1.556 4.795 4.795 0 0 1 0 4.729 4.124 4.124 0 0 1-1.754 1.559 5.865 5.865 0 0 1-2.594.55H.199Zm4.107 6.926a2.574 2.574 0 0 0 1.838-.652 2.69 2.69 0 0 0 0-3.6 2.574 2.574 0 0 0-1.838-.652H2.717v4.907Z"
      />
      <path
        data-name="Path 13864"
        d="M16.808 78.081h-3.764l-.7 1.738H9.776l3.929-8.946h2.48l3.942 8.946h-2.62Zm-.738-1.866-1.144-2.863-1.144 2.863Z"
      />
      <path
        data-name="Path 13865"
        d="M22.366 72.881h-2.733v-2.006h7.973v2.006h-2.722v6.939h-2.518Z"
      />
      <path
        data-name="Path 13866"
        d="M34.155 78.081h-3.764l-.7 1.738h-2.568l3.929-8.946h2.484l3.942 8.946h-2.624Zm-.738-1.866-1.144-2.863-1.144 2.863Z"
      />
      <path
        data-name="Path 13867"
        d="M40.023 79.731a5.138 5.138 0 0 1-1.653-.716l.827-1.866a5.336 5.336 0 0 0 1.386.639 5.251 5.251 0 0 0 1.551.243q1.45 0 1.45-.728a.6.6 0 0 0-.413-.569 7.583 7.583 0 0 0-1.329-.39 12.567 12.567 0 0 1-1.679-.467 2.825 2.825 0 0 1-1.157-.8 2.165 2.165 0 0 1-.483-1.482 2.576 2.576 0 0 1 .445-1.476 2.981 2.981 0 0 1 1.329-1.041 5.443 5.443 0 0 1 2.168-.383 7.612 7.612 0 0 1 1.729.2 5.3 5.3 0 0 1 1.5.582l-.776 1.879a5.163 5.163 0 0 0-2.467-.69 2.063 2.063 0 0 0-1.094.223.673.673 0 0 0-.343.582.576.576 0 0 0 .407.537 7.3 7.3 0 0 0 1.31.371 12.275 12.275 0 0 1 1.685.467 2.907 2.907 0 0 1 1.157.792 2.124 2.124 0 0 1 .49 1.476 2.525 2.525 0 0 1-.445 1.457 3.036 3.036 0 0 1-1.335 1.041 5.375 5.375 0 0 1-2.161.39 8.193 8.193 0 0 1-2.1-.268"
      />
      <path
        data-name="Path 13868"
        d="M49.749 72.881h-2.732v-2.006h7.973v2.006h-2.723v6.939h-2.518Z"
      />
      <path
        data-name="Path 13869"
        d="M60.328 77.444h-1.373v2.377h-2.514v-8.946h4.069a5.049 5.049 0 0 1 2.1.4 3.116 3.116 0 0 1 1.373 1.15 3.163 3.163 0 0 1 .484 1.757 3.126 3.126 0 0 1-.452 1.693 3.056 3.056 0 0 1-1.29 1.131l1.92 2.811h-2.7Zm1.2-4.23a1.732 1.732 0 0 0-1.17-.345h-1.4v2.62h1.4a1.754 1.754 0 0 0 1.17-.339 1.2 1.2 0 0 0 .394-.965 1.222 1.222 0 0 0-.394-.971"
      />
      <path
        data-name="Path 13870"
        d="M73.601 77.866v1.955h-7.146v-8.946h6.98v1.955h-4.488v1.508h3.954v1.891h-3.954v1.636Z"
      />
      <path
        data-name="Path 13871"
        d="M81.781 78.081h-3.76l-.7 1.738h-2.569l3.929-8.946h2.48l3.942 8.946h-2.622Zm-.738-1.866-1.144-2.863-1.145 2.863Z"
      />
      <path
        data-name="Path 13872"
        d="m94.474 79.821-.025-4.741-2.289 3.859h-1.119l-2.276-3.732v4.613h-2.327v-8.946h2.072l3.128 5.163 3.051-5.163h2.073l.025 8.946Z"
      />
    </g>
  </svg>
);
