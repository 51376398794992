import GraphDateControls from "@solarschools/component-lib/components/GraphDateControls";
import { appLayoutConfig } from "layouts/withAppLayout/withAppLayout.config";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { StyledButton } from "components/ui/StyledButton/StyledButton";
import { FormLabel, TextField } from "@material-ui/core";
import { createGlobalStyle } from "styled-components";
import styled from "providers/ThemeProvider";
import React from "react";
import {
  TableContainer as MuiTableContainer,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  createStyles,
  withStyles,
  IconButton,
  Typography,
  Tooltip,
  Grid,
  Box,
} from "@material-ui/core";

export const PageContainer = styled.div({
  height: `calc(100vh - (${appLayoutConfig.headerHeight} + ${appLayoutConfig.footerHeight}))`,
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  display: "flex",
  width: "100%",
});

export const DashboardContainer = styled.div<{
  siteOnly?: boolean;
  noPadding?: boolean;
}>(({ theme, siteOnly, noPadding }) => ({
  marginLeft: siteOnly ? 0 : appLayoutConfig.navbarWidth,

  ...(!noPadding && {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingBottom: "5rem",
    paddingTop: "1.6em",

    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(2.5),
    },
  }),
  height: "100%",
  [theme.breakpoints.down(appLayoutConfig.mobileBreakpoint)]: {
    marginLeft: 0,
  },
}));

export const ContentGrid = styled(Grid).attrs({ xs: 12, lg: 6, item: true })(
  ({ theme }) => ({
    "& > div:not(:last-child)": {
      marginBottom: theme.spacing(4),
    },

    // removes double padding when it collapses
    [`${theme.breakpoints.down("md")}`]: {
      "&:not(:last-child)": {
        paddingBottom: "0px !important",
      },
    },
  })
);
// Table Styles
// have to do HOC because you cant pass props to withStyles
export const TableContainer = ({
  overrideMaxHeight,
  hideborders,
  children,
}: {
  overrideMaxHeight?: string;
  hideborders?: boolean;
  children: JSX.Element;
}) => {
  const Container = withStyles((theme) => ({
    root: {
      ...(!hideborders && { border: `1px solid ${theme.palette.grey[300]}` }),
      maxHeight: overrideMaxHeight
        ? overrideMaxHeight
        : `calc(100vh - 14rem - (${appLayoutConfig.headerHeight} + ${appLayoutConfig.footerHeight}))`,
    },
  }))(MuiTableContainer);
  return <Container>{children}</Container>;
};

export const TableToolbarSort = styled(TextField).attrs({
  variant: "outlined",
  select: true,
  size: "small",
})({
  minWidth: "10rem",
});

export const TableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.grey[200],
      borderBottom: "none",
    },
    body: {
      fontSize: theme.typography.caption.fontSize,
    },
  })
)(MuiTableCell);

export const TableRow = styled(MuiTableRow)<{ hideborders?: number }>(
  ({ hideborders }) => ({
    ...(hideborders ? { "& > td": { border: "none" } } : {}),
  })
);

export const HeadingSortSpan = styled.span({
  clip: "rect(0 0 0 0)",
  position: "absolute",
  overflow: "hidden",
  margin: -1,
  padding: 0,
  border: 0,
  height: 1,
  width: 1,
  top: 20,
});

export const MenuButton = styled(IconButton)({
  outline: "none !important",
});

export const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: theme.typography.body1.fontSize,
    boxShadow: theme.shadows[15],
  },
}))(Tooltip);

export const ChartContainer = styled.div<{ height: number }>(({ height }) => ({
  width: "100%",
  height: `${height}px`,
}));

export const OutlinedContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  width: "100%",
}));

export const PageHeading = styled(Typography).attrs({ variant: "h6" })(
  ({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
  })
);

export const StyledDateControls = styled(GraphDateControls)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  padding: "0 !important",

  "& > div > svg": {
    height: "1.5rem",
    width: "1.5rem",
  },
}));

export const DateTitle = styled(Typography).attrs({ variant: "body1" })(
  ({ theme }) => ({
    fontWeight: theme.typography.fontWeightRegular,
  })
);

export const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.primary.main,
  marginBottom: 0,
  width: "100%",
}));

export const StyledTextField = styled(TextField).attrs({
  variant: "outlined",
})(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.common.white,
  marginBottom: theme.spacing(1),
}));

export const FieldContainer = styled.div(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: "100%",
}));

export const DropdownContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[3],
  zIndex: theme.zIndex.modal,
  position: "absolute",
  width: "100%",
}));

export const FlexBox = styled.div({
  alignItems: "center",
  display: "flex",
});

export const PaddedFlexBox = styled.div<{
  justifyContent?: string;
  lastPadding?: number;
  alignItems?: string;
  fullWidth?: boolean;
  spacing?: number;
}>(
  ({
    justifyContent = "flex-start",
    alignItems = "inherit",
    lastPadding,
    fullWidth,
    spacing,
    theme,
  }) => ({
    ...(fullWidth && { width: "100%" }),
    justifyContent: justifyContent,
    alignItems: alignItems,
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(spacing || 2),
    },
    ...(lastPadding && {
      "& > *:last-child": {
        marginRight: theme.spacing(spacing !== undefined ? spacing : 1),
      },
    }),
  })
);

export const PaddedFlexBoxColumn = styled.div<{
  lastPadding?: number;
  alignItems?: string;
  spacing?: number;
}>(({ theme, spacing, alignItems, lastPadding }) => ({
  ...(alignItems && { alignItems }),
  flexDirection: "column",
  display: "flex",
  "& > *:not(:last-child)": {
    marginBottom: theme.spacing(spacing !== undefined ? spacing : 1),
  },
  ...(lastPadding && {
    "& > *:last-child": {
      marginBottom: theme.spacing(spacing !== undefined ? spacing : 1),
    },
  }),
}));

export const WizardSubmitButton = styled(StyledButton).attrs({
  colorvariant: "success",
  variant: "contained",
})(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const WizardCancelButton = styled(StyledButton).attrs({
  colorvariant: "cancel",
  variant: "contained",
})(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const GreyContainer = styled.div<{ darker?: boolean }>(
  ({ theme, darker }) => ({
    backgroundColor: theme.palette.grey[darker ? 200 : 100],
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    width: "100%",
  })
);

export const HideOnBreakpoint = styled.div<{
  breakpoint: Breakpoint;
  action?: "up" | "down";
}>(({ theme, breakpoint, action = "down" }) => ({
  [`${theme.breakpoints[action](breakpoint)}`]: {
    display: "none",
  },
}));

export const ShowOnBreakpoint = styled.div<{
  breakpoint: Breakpoint;
  action?: "up" | "down";
}>(({ theme, breakpoint, action = "down" }) => ({
  display: "none",
  [`${theme.breakpoints[action](breakpoint)}`]: {
    display: "block",
  },
}));

export const ToggleableText = styled(Typography).attrs({ variant: "caption" })<{
  active?: number;
}>(({ theme, active }) => ({
  color: theme.palette.grey[500],
  cursor: "pointer",
  ...(active && {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  }),
  "&:hover": {
    textDecoration: "underline",
  },
}));

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
  } 
`;
