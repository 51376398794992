import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "./index.scss";
import { RouterStore, syncHistoryWithStore } from "mobx-react-router";
import { ThemeProvider, StoreProvider } from "providers";
import * as serviceWorker from "./serviceWorker";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { App } from "components/App";
import ReactDOM from "react-dom";
import React from "react";
import { GlobalStyle } from "global.styles";

const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();
const history = syncHistoryWithStore(browserHistory, routingStore);
serviceWorker.unregister();

const boot = async () => {
  ReactDOM.render(
    <Router history={history}>
      <StoreProvider routingStore={routingStore}>
        <ThemeProvider>
          <GlobalStyle />
          <App />
        </ThemeProvider>
      </StoreProvider>
    </Router>,
    document.getElementById("root")
  );
};

boot();
