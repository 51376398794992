import React, { FunctionComponent, createContext, useContext } from "react";
import { StoreProviderProps, SessionStore } from "./StoreProvider.types";
import { createStore, hydrateStore } from "./StoreProvider.utils";
import { SessionHandler } from "./SessionHandler";
import { useLocalStore } from "mobx-react-lite";
import { RouterStore } from "mobx-react-router";

const StoreContext = createContext<SessionStore | undefined>(undefined);
const RouterContext = createContext<RouterStore | undefined>(undefined);

export const useSessionStore = () => {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw Error("useSessionStore must be used within a StoreProvider");
  }
  return context;
};

export const useHistory = () => {
  const context = useContext(RouterContext);
  if (context === undefined) {
    throw Error("useHistory must be used within a RouterProvider");
  }
  return context;
};
export const StoreProvider: FunctionComponent<StoreProviderProps> = ({
  children,
  routingStore,
}) => {
  const store = useLocalStore(() => createStore(hydrateStore()));

  return (
    <RouterContext.Provider value={routingStore}>
      <StoreContext.Provider value={store}>
        <SessionHandler>{children}</SessionHandler>
      </StoreContext.Provider>
    </RouterContext.Provider>
  );
};
