import { ThemeAssetState } from "./ThemeProvider.types";
import { createContext } from "react";
import {
  SolarSchoolsLogo,
  SolarSchoolsNavMenuBG,
  SolarSchoolsLoader
} from "./themes";

export const ThemeAssetContext = createContext<ThemeAssetState>({
  Logo: SolarSchoolsLogo,
  navMenuBG: SolarSchoolsNavMenuBG,
  loader: SolarSchoolsLoader
});
