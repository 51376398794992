import { SchemasApi, Configuration } from "@solarschools/api-sdk-typescript";
import { SchemaSDK } from "./Schemas.types";
import { CancelToken } from "axios";

export const fetchSchemaResource = async (
  props: SchemaSDK,
  config: Configuration,
  token: CancelToken
) => {
  const client = new SchemasApi(config);
  switch (props.method) {
    case "getById": {
      const { id } = props.params;
      const { data } = await client.get(id, { cancelToken: token });
      return data;
    }
    case "getContractors": {
      const { id, page, limit, status, name, email, state, country } =
        props.params;
      const { data } = await client.getContractors(
        id,
        page,
        limit,
        status,
        name,
        email,
        state,
        country,
        {
          cancelToken: token,
        }
      );
      return data;
    }
    case "getEnergyBreakdown": {
      const { id } = props.params;
      const { data } = await client.getEnergyBreakdown(id, {
        cancelToken: token,
      });
      return data;
    }
    case "getEnergyDataFeed": {
      const { id, start, end, aggregation } = props.params;
      const { data } = await client.getEnergyDataFeed(
        id,
        start,
        end,
        aggregation,
        { cancelToken: token }
      );
      return data;
    }
    case "getList": {
      const { data } = await client.getList(props?.params?.type, {
        cancelToken: token,
      });
      return data;
    }
    case "getMapSites": {
      const { id, filter } = props.params;
      const { data } = await client.getMapSites(id, filter, {
        cancelToken: token,
      });
      return data;
    }
    case "getSites": {
      const { id, page, limit, filter } = props.params;
      const { data } = await client.getSites(id, page, limit, filter, {
        cancelToken: token,
      });
      return data;
    }
    case "getSitesSolarReport": {
      const { id, phase } = props.params;
      const { data } = await client.getSitesSolarReport(id, phase, {
        cancelToken: token,
      });
      return data;
    }
    case "getSolarCurrentStats": {
      const { id } = props.params;
      const { data } = await client.getSolarCurrentStats(id, {
        cancelToken: token,
      });
      return data;
    }
    case "getSummary": {
      const { id, timezone } = props.params;
      const { data } = await client.getSummary(id, timezone, {
        cancelToken: token,
      });
      return data;
    }
    case "searchSites": {
      const { id, term, page, limit, timezone } = props.params;
      const { data } = await client.searchSites(
        id,
        term,
        page,
        limit,
        timezone,
        { cancelToken: token }
      );
      return data;
    }
    case "siteRankings": {
      const { id, limit, orderBy, order } = props.params;
      const { data } = await client.siteRankings(id, limit, orderBy, order, {
        cancelToken: token,
      });
      return data;
    }
    default:
      return undefined;
  }
};
