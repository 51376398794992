import { lighten } from "@material-ui/core";
import {
  createTheme,
  Theme,
  darken,
  getContrastRatio,
  rgbToHex,
} from "@material-ui/core/styles";
import { getTintPalette } from "../../ThemeProvider.utilities";

// Solar schools colours
const orange = "#EA7624";
const grey = "#808080";
const darkGrey = "#5E5D63";
const blue = "#2492D3";
const blueGreen = "#0dbab1";
const green = "#24A21A";
const limeGreen = "#95DD19";
const vividOrange = "#FFB50A";
const brown = "#9D4D24";
const darkBlue = "#005A80";
const red = "#CC1C00";

const primary = "#EA7624";

const contrastThreshold = 3;

export const SolarSchoolsTheme: Theme = createTheme({
  overrides: {
    MuiTablePagination: {
      toolbar: {
        "@media(min-width:600px)": {
          paddingLeft: 0,
        },
      },
    },
    MuiButton: {
      root: {
        "&:focus, &:active": {
          outline: "none",
        },
      },
    },
    MuiIconButton: {
      root: {
        "&:focus, &:active": {
          outline: "none",
        },
      },
    },
    MuiMenu: {
      paper: {
        root: {
          "&:focus": {
            outline: "none !important",
          },
        },
      },
      list: {
        root: {
          "&:focus": {
            outline: "none !important",
          },
        },
      },
    },
    MuiTab: {
      root: {
        outline: "none !important",
      },
    },
    MuiLink: {
      root: {
        cursor: "pointer",
      },
    },
  },
  palette: {
    severities: {
      insignificant: "#FFB74D",
      moderate: "#FF6500",
      critical: "#CB0032",
    },
    colors: {
      orange: getTintPalette(orange),
      grey: getTintPalette(grey),
      darkGrey: getTintPalette(darkGrey),
      blue: getTintPalette(blue),
      green: getTintPalette(green),
      limeGreen: getTintPalette(limeGreen),
      vividOrange: getTintPalette(vividOrange),
      brown: getTintPalette(brown),
      darkBlue: getTintPalette(darkBlue),
      red: getTintPalette(red),
    },
    dataKeys: {
      solarGenerated: green,
      gridConsumed: rgbToHex(darken(grey, 0.2)),
      solarConsumed: vividOrange,
      selfConsumed: vividOrange,
      solarReturned: orange,
      solarFeedIn: orange,
      netConsumed: "#000000",
      co2Saved: limeGreen,
      co2Produced: rgbToHex(darken(grey, 0.4)),
      energyTarget: "#000000",
      expectedGeneration: green,
      batteryStored: blue,
      batteryFeedIn: darken(blue, 0.35),
      gridStored: darkBlue,
      solarStored: blueGreen,
    },
    primary: {
      dark: darken(primary, 0.2),
      main: primary,
      light: lighten(primary, 0.2),
    },
    secondary: {
      main: "#5E5D63",
    },
    error: {
      main: "#CC1C00",
    },
    text: {
      primary: "#5E5D63",
    },
    contrastThreshold: contrastThreshold,
    getContrastText: (background) => {
      if (!background) {
        throw new TypeError(
          `Material-UI: missing background argument in getContrastText(${background}).`
        );
      }

      if (background === primary) {
        return "#FFFFFF";
      }

      const contrastText =
        getContrastRatio(background, "#FFFFFF") >= contrastThreshold
          ? "#FFFFFF"
          : "rgba(0, 0, 0, 0.87)";

      return contrastText;
    },
  },
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(","),
    fontWeightRegular: 400,
    fontWeightBold: 900,
    body1: { fontSize: "0.875rem" },
    body2: { fontSize: "0.8rem" },
  },
});
