import React, { FunctionComponent } from "react";

export const SolarSchoolsLogo: FunctionComponent<
  React.SVGProps<SVGSVGElement>
> = props => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 146 30.7" {...props}>
      <path
        fill="#FFFFFF"
        d="M21.3,8.4c-4.4-4-8.4-4-13,0.2c-2.1,1.9-2.7,5-1.7,8.2c0.7,2.1,1.9,4.1,3.6,5.6c1.8,1.7,4,2.7,6.3,3h0.1
	c0.3,0,0.5-0.2,0.5-0.5c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.2c-2.1-0.3-4.1-1.2-5.8-2.7c-1.5-1.4-2.7-3.2-3.3-5.1
	C6.7,13.6,7.2,11,9,9.3c4.2-3.8,7.7-3.9,11.7-0.2c3.4,3.1,1.9,7.4-0.7,10.4c-1,1.1-2.1,1.8-3.2,1.8c-1.3,0.1-2.7-0.5-4.1-1.8
	c-2-1.9-2.2-5-0.5-7c0.6-0.7,1.5-1.2,2.5-1.2c1-0.1,2,0.3,2.8,1c1.2,1.1,1.3,3,0.3,4.2c-0.4,0.4-0.9,0.7-1.5,0.7s-1.2-0.2-1.6-0.6
	c-0.7-0.6-0.8-1.7-0.2-2.4c0.2-0.2,0.5-0.4,0.8-0.4c0.1,0,0.1,0,0.2,0c-0.3,0-0.6,0.2-0.8,0.4c-0.5,0.6-0.4,1.5,0.1,2
	c0.3,0.3,0.8,0.5,1.2,0.4c0.4,0,0.8-0.2,1.1-0.5c0.7-0.8,0.6-2-0.2-2.7c-0.5-0.4-1.1-0.6-1.7-0.6s-1.1,0.3-1.5,0.8
	c-1,1.1-0.8,2.9,0.3,3.9c0.7,0.6,1.5,0.9,2.4,0.9c0.8,0,1.6-0.4,2.2-1.1c1.4-1.6,1.2-4.2-0.4-5.7c-1-0.9-2.2-1.3-3.5-1.3
	c-1.2,0.1-2.4,0.6-3.2,1.6c-2.1,2.4-1.8,6.2,0.6,8.4c1.6,1.5,3.3,2.2,4.8,2.1c1.4-0.1,2.8-0.8,3.9-2.2c1.7-1.9,2.6-4,2.9-5.9
	C23.8,12.1,23,10,21.3,8.4z"
      />
      <path
        fill="#FFFFFF"
        d="M14.8,4.5c0.3,0,0.5-0.2,0.5-0.5V0.5c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5v3.6C14.3,4.3,14.5,4.5,14.8,4.5z"
      />
      <path
        fill="#FFFFFF"
        d="M17.2,26.5h-5c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h5c0.3,0,0.5-0.2,0.5-0.5S17.5,26.5,17.2,26.5z"
      />
      <path
        fill="#FFFFFF"
        d="M17.2,29.7h-5c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h5c0.3,0,0.5-0.2,0.5-0.5S17.5,29.7,17.2,29.7z"
      />
      <path
        fill="#FFFFFF"
        d="M17.2,28.1h-5c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h5c0.3,0,0.5-0.2,0.5-0.5C17.7,28.3,17.5,28.1,17.2,28.1z
	"
      />
      <path
        fill="#FFFFFF"
        d="M28.8,14.7h-3.6c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h3.6c0.3,0,0.5-0.2,0.5-0.5S29,14.7,28.8,14.7z"
      />
      <path
        fill="#FFFFFF"
        d="M4.6,15.2c0-0.3-0.2-0.5-0.5-0.5H0.5c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h3.6C4.3,15.7,4.6,15.4,4.6,15.2z"
      />
      <path
        fill="#FFFFFF"
        d="M7,22.5L4.5,25c-0.1,0.1-0.1,0.2-0.1,0.3s0.1,0.3,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1s0.3-0.1,0.3-0.1l2.5-2.5
	c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.3-0.1-0.3C7.5,22.3,7.2,22.3,7,22.5z"
      />
      <path
        fill="#FFFFFF"
        d="M22.5,22.5c-0.2-0.2-0.5-0.2-0.7,0c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0.1,0.3,0.1,0.3l2.5,2.5
	c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3-0.1,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3S24.8,25,24.8,25L22.5,22.5z"
      />
      <path
        fill="#FFFFFF"
        d="M22.4,8c0.1,0,0.3-0.1,0.3-0.1l2.5-2.5c0.1-0.1,0.1-0.2,0.1-0.3s-0.1-0.3-0.1-0.3c-0.2-0.2-0.5-0.2-0.7,0
	L22,7.3c-0.1,0.1-0.1,0.2-0.1,0.3S22,7.9,22,7.9C22.1,8,22.3,8,22.4,8z"
      />
      <path
        fill="#FFFFFF"
        d="M6.9,7.9C7,8,7.1,8,7.2,8s0.3-0.1,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3S7.5,7.3,7.5,7.3L5,4.7
	c-0.2-0.2-0.5-0.2-0.7,0C4.2,4.8,4.2,4.9,4.2,5s0.1,0.3,0.1,0.3L6.9,7.9z"
      />
      <g>
        <path
          fill="#FFFFFF"
          d="M33.9,13.8c0,0,1.3,1.4,3.2,1.4c1.2,0,2.2-0.7,2.2-1.9c0-2.7-6.1-2.1-6.1-6c0-2,1.7-3.5,4.1-3.5
		c2.3,0,3.6,1.3,3.6,1.3l-0.8,1.5c0,0-1.2-1.1-2.8-1.1C36,5.5,35,6.3,35,7.3c0,2.6,6.1,1.9,6.1,6c0,1.9-1.5,3.5-4,3.5
		c-2.7,0-4.2-1.7-4.2-1.7L33.9,13.8z"
        />
        <path
          fill="#FFFFFF"
          d="M47.7,7.4c2.7,0,4.9,2,4.9,4.7s-2.2,4.7-4.9,4.7s-4.9-2-4.9-4.7C42.8,9.4,45,7.4,47.7,7.4z M47.7,15.3
		c1.7,0,3.1-1.4,3.1-3.2c0-1.9-1.4-3.2-3.1-3.2c-1.7,0-3.1,1.3-3.1,3.2C44.6,14,46,15.3,47.7,15.3z"
        />
        <path
          fill="#FFFFFF"
          d="M54.7,4h1.7v9.8c0,1.1,0.4,1.3,1,1.3c0.2,0,0.3,0,0.3,0v1.6c0,0-0.3,0-0.6,0c-1,0-2.4-0.3-2.4-2.5V4z"
        />
        <path
          fill="#FFFFFF"
          d="M64.5,11h0.4v-0.2c0-1.5-0.8-2-2-2c-1.4,0-2.5,0.9-2.5,0.9l-0.7-1.3c0,0,1.3-1.1,3.4-1.1
		c2.3,0,3.6,1.2,3.6,3.5v5.7h-1.6v-0.9c0-0.4,0-0.7,0-0.7l0,0c0,0-0.7,1.8-2.9,1.8c-1.5,0-3.1-0.9-3.1-2.7C59.1,11.1,63,11,64.5,11z
		 M62.5,15.4c1.5,0,2.4-1.5,2.4-2.9v-0.3h-0.4c-1.3,0-3.7,0.1-3.7,1.7C60.8,14.7,61.4,15.4,62.5,15.4z"
        />
        <path
          fill="#FFFFFF"
          d="M69.4,7.6h1.7v1.6c0,0.4,0,0.7,0,0.7l0,0c0.4-1.3,1.5-2.4,2.8-2.4c0.2,0,0.4,0,0.4,0v1.7c0,0-0.2-0.1-0.5-0.1
		c-1.1,0-2.1,0.8-2.5,2.1c-0.2,0.5-0.2,1.1-0.2,1.6v3.7h-1.7C69.4,16.5,69.4,7.6,69.4,7.6z"
        />
      </g>
      <g>
        <path
          fill="#FFFFFF"
          d="M81.8,13.8c0,0,1.3,1.4,3.2,1.4c1.2,0,2.2-0.7,2.2-1.9c0-2.7-6.1-2.1-6.1-6c0-2,1.7-3.5,4.1-3.5
		c2.3,0,3.6,1.3,3.6,1.3L88,6.6c0,0-1.2-1.1-2.8-1.1c-1.3,0-2.3,0.8-2.3,1.8c0,2.6,6.1,1.9,6.1,6c0,1.9-1.5,3.5-4,3.5
		c-2.7,0-4.2-1.7-4.2-1.7L81.8,13.8z"
        />
        <path
          fill="#FFFFFF"
          d="M95.6,7.4c2.3,0,3.4,1.3,3.4,1.3l-0.8,1.2c0,0-1-1-2.5-1c-1.8,0-3.1,1.4-3.1,3.2c0,1.8,1.3,3.2,3.2,3.2
		c1.7,0,2.8-1.2,2.8-1.2l0.7,1.3c0,0-1.2,1.4-3.6,1.4c-2.8,0-4.8-2-4.8-4.7C90.7,9.4,92.7,7.4,95.6,7.4z"
        />
        <path
          fill="#FFFFFF"
          d="M101.4,4h1.7v4.7c0,0.4,0,0.7,0,0.7l0,0c0.4-0.9,1.5-2,3.3-2c2.1,0,3.1,1.2,3.1,3.5v5.8h-1.7v-5.4
		c0-1.3-0.3-2.3-1.7-2.3c-1.4,0-2.5,0.9-2.8,2.2c-0.1,0.4-0.1,0.8-0.1,1.2v4.2h-1.7V4H101.4z"
        />
        <path
          fill="#FFFFFF"
          d="M116.6,7.4c2.7,0,4.9,2,4.9,4.7s-2.2,4.7-4.9,4.7c-2.7,0-4.9-2-4.9-4.7C111.7,9.4,113.9,7.4,116.6,7.4z
		 M116.6,15.3c1.7,0,3.1-1.4,3.1-3.2c0-1.9-1.4-3.2-3.1-3.2s-3.1,1.3-3.1,3.2C113.5,14,114.9,15.3,116.6,15.3z"
        />
        <path
          fill="#FFFFFF"
          d="M127.9,7.4c2.7,0,4.9,2,4.9,4.7s-2.2,4.7-4.9,4.7c-2.7,0-4.9-2-4.9-4.7C123,9.4,125.2,7.4,127.9,7.4z
		 M127.9,15.3c1.7,0,3.1-1.4,3.1-3.2c0-1.9-1.4-3.2-3.1-3.2c-1.7,0-3.1,1.3-3.1,3.2C124.8,14,126.2,15.3,127.9,15.3z"
        />
        <path
          fill="#FFFFFF"
          d="M135,4h1.7v9.8c0,1.1,0.4,1.3,1,1.3c0.2,0,0.3,0,0.3,0v1.6c0,0-0.3,0-0.6,0c-1,0-2.4-0.3-2.4-2.5V4z"
        />
        <path
          fill="#FFFFFF"
          d="M140.1,14.3c0,0,1,1,2.6,1c0.7,0,1.5-0.4,1.5-1.1c0-1.7-4.6-1.3-4.6-4.3c0-1.6,1.5-2.6,3.3-2.6
		c2,0,2.9,1,2.9,1l-0.7,1.3c0,0-0.8-0.8-2.2-0.8c-0.7,0-1.5,0.3-1.5,1.1c0,1.6,4.6,1.3,4.6,4.2c0,1.5-1.3,2.6-3.3,2.6
		c-2.2,0-3.4-1.3-3.4-1.3L140.1,14.3z"
        />
      </g>
      <g>
        <path
          fill="#FFFFFF"
          d="M33.7,23.1h-0.8v-0.9h0.8v-1.8h1.1v1.8h1.5v0.9h-1.5v2.7c0,1.2,0.9,1.4,1.3,1.4c0.2,0,0.3,0,0.3,0v1
		c0,0-0.2,0-0.4,0c-0.8,0-2.3-0.2-2.3-2.3C33.7,25.9,33.7,23.1,33.7,23.1z"
        />
        <path
          fill="#FFFFFF"
          d="M39.3,19.7h1.2v3.1c0,0.3,0,0.5,0,0.5l0,0c0.3-0.6,1-1.4,2.2-1.4c1.4,0,2.1,0.8,2.1,2.3v3.9h-1.2v-3.6
		c0-0.9-0.2-1.5-1.2-1.5c-0.9,0-1.7,0.6-1.9,1.5c-0.1,0.2-0.1,0.5-0.1,0.8v2.8h-1.2v-8.4H39.3z"
        />
        <path
          fill="#FFFFFF"
          d="M50.7,21.9c1.7,0,2.6,1.3,2.6,2.8c0,0.2,0,0.5,0,0.5h-4.4c0.1,1.3,1,2,2.1,2c1,0,1.8-0.7,1.8-0.7l0.5,0.9
		c0,0-0.9,0.9-2.4,0.9c-1.9,0-3.2-1.4-3.2-3.2C47.6,23.2,48.9,21.9,50.7,21.9z M52.1,24.4c0-1-0.7-1.5-1.5-1.5
		c-0.9,0-1.6,0.6-1.8,1.5H52.1z"
        />
        <path
          fill="#FFFFFF"
          d="M63.5,21.9c1.7,0,2.6,1.3,2.6,2.8c0,0.2,0,0.5,0,0.5h-4.5c0.1,1.3,1,2,2.1,2c1,0,1.8-0.7,1.8-0.7l0.5,0.9
		c0,0-0.9,0.9-2.4,0.9c-1.9,0-3.2-1.4-3.2-3.2C60.4,23.2,61.7,21.9,63.5,21.9z M64.9,24.4c0-1-0.7-1.5-1.5-1.5
		c-0.9,0-1.6,0.6-1.8,1.5H64.9z"
        />
        <path
          fill="#FFFFFF"
          d="M69.1,22.1h1.1v0.8c0,0.2,0,0.4,0,0.4l0,0c0.2-0.5,1-1.4,2.3-1.4c1.4,0,2.1,0.8,2.1,2.3v3.9h-1.2v-3.6
		c0-0.9-0.2-1.5-1.2-1.5c-0.9,0-1.7,0.6-1.9,1.5c-0.1,0.2-0.1,0.5-0.1,0.8v2.8H69L69.1,22.1L69.1,22.1z"
        />
        <path
          fill="#FFFFFF"
          d="M80,29.6c1.1,0,1.9-0.5,1.9-1.7v-0.4c0-0.2,0-0.4,0-0.4l0,0C81.5,27.7,81,28,80.1,28c-1.7,0-2.7-1.4-2.7-3.1
		c0-1.7,1-3,2.7-3c1.5,0,1.9,0.9,1.9,0.9l0,0c0,0,0-0.1,0-0.2V22h1.1v5.8c0,2-1.5,2.8-3.1,2.8c-0.7,0-1.5-0.2-2.1-0.5l0.4-0.9
		C78.4,29.2,79.1,29.6,80,29.6z M82,25c0-1.6-0.8-2-1.7-2c-1.1,0-1.7,0.8-1.7,2c0,1.3,0.7,2.1,1.8,2.1C81.2,27.1,82,26.6,82,25z"
        />
        <path
          fill="#FFFFFF"
          d="M86.5,19.7h1.1v1.2h-1.2L86.5,19.7L86.5,19.7z M86.5,22.1h1.1v6.1h-1.2L86.5,22.1L86.5,22.1z"
        />
        <path
          fill="#FFFFFF"
          d="M91,22.1h1.1v0.8c0,0.2,0,0.4,0,0.4l0,0c0.2-0.5,1-1.4,2.3-1.4c1.4,0,2.1,0.8,2.1,2.3v3.9h-1.2v-3.6
		c0-0.9-0.2-1.5-1.2-1.5c-0.9,0-1.7,0.6-1.9,1.5c-0.1,0.2-0.1,0.5-0.1,0.8v2.8h-1.2L91,22.1L91,22.1z"
        />
        <path
          fill="#FFFFFF"
          d="M102.4,21.9c1.7,0,2.6,1.3,2.6,2.8c0,0.2,0,0.5,0,0.5h-4.4c0.1,1.3,1,2,2.1,2c1,0,1.8-0.7,1.8-0.7l0.5,0.9
		c0,0-0.9,0.9-2.4,0.9c-1.9,0-3.2-1.4-3.2-3.2C99.4,23.2,100.7,21.9,102.4,21.9z M103.8,24.4c0-1-0.7-1.5-1.5-1.5
		c-0.9,0-1.6,0.6-1.8,1.5H103.8z"
        />
        <path
          fill="#FFFFFF"
          d="M112.6,22.1h1.1v1.1c0,0.3,0,0.5,0,0.5l0,0c0.3-0.9,1-1.6,1.9-1.6c0.2,0,0.3,0,0.3,0v1.2c0,0-0.2,0-0.3,0
		c-0.7,0-1.4,0.5-1.7,1.4c-0.1,0.3-0.1,0.7-0.1,1.1v2.5h-1.2V22.1z"
        />
        <path
          fill="#FFFFFF"
          d="M121.4,21.9c1.8,0,3.3,1.3,3.3,3.2c0,1.8-1.5,3.2-3.3,3.2s-3.3-1.3-3.3-3.2C118.1,23.3,119.6,21.9,121.4,21.9z
		 M121.4,27.3c1.2,0,2.1-0.9,2.1-2.2s-0.9-2.2-2.1-2.2c-1.1,0-2.1,0.9-2.1,2.2C119.3,26.4,120.3,27.3,121.4,27.3z"
        />
        <path
          fill="#FFFFFF"
          d="M130.4,21.9c1.8,0,3.3,1.3,3.3,3.2c0,1.8-1.5,3.2-3.3,3.2s-3.3-1.3-3.3-3.2C127.2,23.3,128.6,21.9,130.4,21.9z
		 M130.4,27.3c1.2,0,2.1-0.9,2.1-2.2s-0.9-2.2-2.1-2.2c-1.1,0-2.1,0.9-2.1,2.2C128.4,26.4,129.3,27.3,130.4,27.3z"
        />
        <path
          fill="#FFFFFF"
          d="M136.6,22.1h1.1v0.8c0,0.2,0,0.4,0,0.4l0,0c0.3-0.7,1.1-1.4,2.1-1.4s1.6,0.5,1.8,1.4l0,0
		c0.3-0.7,1.2-1.4,2.2-1.4c1.4,0,2,0.8,2,2.3v3.9h-1.2v-3.6c0-0.9-0.2-1.5-1.1-1.5c-0.9,0-1.5,0.7-1.7,1.5c-0.1,0.3-0.1,0.5-0.1,0.8
		v2.8h-1.2v-3.6c0-0.8-0.1-1.5-1.1-1.5c-0.9,0-1.5,0.7-1.7,1.6c-0.1,0.2-0.1,0.5-0.1,0.8v2.8h-1V22.1z"
        />
      </g>
    </svg>
  );
};

// export const SolarSchoolsHeaderLogo = styled(Logo)({
//   height: "2em",
//   width: "auto"
// });

// export const SolarSchoolsDisplayLogo = styled(Logo)({
//   height: "auto",
//   marginBottom: "2.5rem",
//   marginLeft: "-1.5rem",
//   width: "25rem"
// });
