import { Configuration } from "@solarschools/api-sdk-typescript";
import { fetchEnv } from "utilities";

export const getConfig = (accessToken: string | null) => {
  const config = new Configuration({
    basePath: fetchEnv({
      key: "API_URI",
      defaultValue: "https://api.solarschools.net",
    }),
    baseOptions: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  });

  return config;
};
