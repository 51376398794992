import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(
  {
    imageIcon: {
      height: "auto",
      width: "8rem",
    },
    fullScreenIcon: {
      height: "15rem",
      width: "auto",
    },
    iconRoot: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      textAlign: "center",
      width: "100%",
    },
  },
  { index: 1 }
);
