import {
  Countries,
  CountryDivisions,
  UtilitySDK,
  WeatherLocations,
  XlatValues,
} from "./Utility.types";
import { Configuration, UtilityApi } from "@solarschools/api-sdk-typescript";
import { CancelToken } from "axios";

export const fetchUtilityResource = async (
  props: UtilitySDK,
  config: Configuration,
  token: CancelToken
) => {
  const client = new UtilityApi(config);

  switch (props.method) {
    case "listXlatValues": {
      const { key, active } = props.params;
      const { data } = await client.listXlatValues(key, active, {
        cancelToken: token,
      });
      return data ? (data as XlatValues) : undefined;
    }
    case "listCountryDivisions": {
      const { countryCode } = props.params;
      const { data } = await client.listCountryDivisions(countryCode, {
        cancelToken: token,
      });
      return data ? (data as CountryDivisions) : undefined;
    }
    case "listCountries": {
      const { data } = await client.listCountries(props.params?.filter, {
        cancelToken: token,
      });
      return data ? (data as Countries) : undefined;
    }
    case "searchWeatherLocations": {
      const {
        term,
        latitude,
        longitude,
        page,
        limit,
        orderBy,
        order,
        timezone,
        statuses,
      } = props.params;
      const { data } = await client.searchWeatherLocations(
        term,
        latitude,
        longitude,
        page,
        limit,
        orderBy,
        order,
        timezone,
        statuses,
        { cancelToken: token }
      );
      return data ? (data as WeatherLocations) : undefined;
    }
    default: {
      throw Error("An invalid Utilitys method was encountered");
    }
  }
};
