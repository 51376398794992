import { AxiosError } from "axios";
import {
  OrganisationSDK,
  DisplayBoardSDK,
  ContractorSDK,
  ReminderSDK,
  UtilitySDK,
  SystemSDK,
  SchemaSDK,
  MeterSDK,
  FaultSDK,
  UsersSDK,
  SiteSDK,
  DataSDK,
  AuthSDK,
  VirtualMeterSDK,
} from "./SDKs";
import {
  ResourceReturnType,
  SDKErrorStatus,
  UseSDKProps,
} from "./useSDK.types";

export const isSchema = <T extends ResourceReturnType>(
  props: UseSDKProps<T>
): props is SchemaSDK => {
  return (props as SchemaSDK).resource === "schemas";
};

export const isSite = <T extends ResourceReturnType>(
  props: UseSDKProps<T>
): props is SiteSDK => {
  return (props as SiteSDK).resource === "sites";
};

export const isOrganisation = <T extends ResourceReturnType>(
  props: UseSDKProps<T>
): props is OrganisationSDK => {
  return (props as OrganisationSDK).resource === "organisations";
};

export const isMeter = <T extends ResourceReturnType>(
  props: UseSDKProps<T>
): props is MeterSDK => {
  return (props as MeterSDK).resource === "meters";
};

export const isSystem = <T extends ResourceReturnType>(
  props: UseSDKProps<T>
): props is SystemSDK => {
  return (props as SystemSDK).resource === "systems";
};

export const isFault = <T extends ResourceReturnType>(
  props: UseSDKProps<T>
): props is FaultSDK => {
  return (props as FaultSDK).resource === "faults";
};

export const isData = <T extends ResourceReturnType>(
  props: UseSDKProps<T>
): props is DataSDK => {
  return (props as DataSDK).resource === "data";
};

export const isAuth = <T extends ResourceReturnType>(
  props: UseSDKProps<T>
): props is AuthSDK => {
  return (props as AuthSDK).resource === "authentication";
};

export const isContractor = <T extends ResourceReturnType>(
  props: UseSDKProps<T>
): props is ContractorSDK => {
  return (props as ContractorSDK).resource === "contractors";
};

export const isUtility = <T extends ResourceReturnType>(
  props: UseSDKProps<T>
): props is UtilitySDK => {
  return (props as UtilitySDK).resource === "utility";
};

export const isDisplayBoard = <T extends ResourceReturnType>(
  props: UseSDKProps<T>
): props is DisplayBoardSDK => {
  return (props as DisplayBoardSDK).resource === "displayboard";
};

export const isUser = <T extends ResourceReturnType>(
  props: UseSDKProps<T>
): props is UsersSDK => {
  return (props as UsersSDK).resource === "users";
};

export const isReminder = <T extends ResourceReturnType>(
  props: UseSDKProps<T>
): props is ReminderSDK => {
  return (props as ReminderSDK).resource === "reminders";
};

export const isVirtualMeter = <T extends ResourceReturnType>(
  props: UseSDKProps<T>
): props is VirtualMeterSDK => {
  return (props as VirtualMeterSDK).resource === "virtualmeters";
};

export const isSDKError = (error: Error): error is AxiosError => {
  return (error as AxiosError).isAxiosError === true;
};

export class SDKError extends Error {
  status: SDKErrorStatus;

  constructor(message: string, status: number = 500) {
    super(message);
    this.name = "SDKError";
    this.status = this.deriveStatusType(status);
  }

  private deriveStatusType(status: number) {
    switch (status) {
      case 401:
      case 403:
        return "UNAUTHORISED";
      case 404:
        return "NOT_FOUND";
      default:
        return "UNEXPECTED_ERROR";
    }
  }
}
