import React, { FunctionComponent, createContext, useContext } from "react";
import { AuthTokens, SessionControlContext } from "./StoreProvider.types";
import { AuthenticationApi } from "@solarschools/api-sdk-typescript";
import { isTokenValid } from "utilities/ValidateToken/ValidateToken";
import { useSDKConfig } from "hooks/useSDKConfig";
import { useIdleTimer } from "react-idle-timer";
import { useSessionStore } from "./StoreProvider";
import { observer } from "mobx-react-lite";
import { useInterval } from "hooks";

const THIRTY_MINUTES = 1.8e6;
const TWENTY_FIVE_MINUTES = 1.5e6;

const SessionControl =
  createContext<SessionControlContext | undefined>(undefined);

export const useSessionControl = () => {
  const context = useContext(SessionControl);
  if (context === undefined) {
    throw Error(
      "useSessionControl must be used within a SessionControlProvider"
    );
  }
  return context;
};

export const SessionHandler: FunctionComponent = observer(({ children }) => {
  const { getConfig } = useSDKConfig();
  const store = useSessionStore();

  const { pause, resume, isIdle } = useIdleTimer({
    timeout: THIRTY_MINUTES,
    onIdle: () => {
      if (isTokenValid(store.accessToken) && !store.session.isLocked) {
        store.lockSession();
      } else {
        pause();
      }
    },
  });

  useInterval(async () => {
    if (isTokenValid(store.accessToken)) {
      const username = store.session.email;
      const refreshToken = store.session.tokens.refreshToken;
      if (refreshToken) {
        try {
          const config = getConfig(store.accessToken);
          const client = new AuthenticationApi(config);
          const { data } = await client.refreshSession("false", {
            username,
            refreshToken,
          });
          const tokens = data as AuthTokens;
          store.refreshTokens(tokens);
        } catch (error) {
          console.error("An error occured during session refresh: ", error);
          store.clearSession();
          window.location.reload();
        }
      }
    }
  }, TWENTY_FIVE_MINUTES);

  return (
    <SessionControl.Provider value={{ pause, resume, isIdle }}>
      {children}
    </SessionControl.Provider>
  );
});
