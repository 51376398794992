import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

export const appLayoutConfig: {
  mobileBreakpoint: Breakpoint;
  headerHeight: string;
  navbarWidth: string;
  footerHeight: string;
} = {
  mobileBreakpoint: "xs",
  headerHeight: "4rem",
  navbarWidth: "7rem",
  footerHeight: "2.5rem",
};
