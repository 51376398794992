import { SitesApi, Configuration } from "@solarschools/api-sdk-typescript";
import { SiteSDK } from "./Sites.types";
import { CancelToken } from "axios";

export const fetchSiteResource = async (
  props: SiteSDK,
  config: Configuration,
  token: CancelToken
) => {
  const client = new SitesApi(config);
  switch (props.method) {
    case "getById": {
      const { id, timezone, customAttributes } = props.params;
      const { data } = await client.get(id, timezone, true, true, true, true, customAttributes, {
        cancelToken: token,
      });
      return data;
    }
    case "getMeters": {
      const { id } = props.params;
      const { data } = await client.getMeters(id, { cancelToken: token });
      return data;
    }
    case "getSavingsGuarantee": {
      const { id, date } = props.params;
      const { data } = await client.getSavingsGuarantee(id, date, {
        cancelToken: token,
      });
      return data;
    }
    case "getSavingsGuaranteeFeed": {
      const { id, date } = props.params;
      const { data } = await client.getSavingsGuaranteeFeed(id, date, {
        cancelToken: token,
      });
      return data;
    }
    case "getSavingsGuaranteeDailyFeed": {
      const { id, date } = props.params;
      const { data } = await client.getSavingsGuaranteeDailyFeed(id, date, {
        cancelToken: token,
      });
      return data;
    }
    case "getSummary": {
      const { id, timezone } = props.params;
      const { data } = await client.getSummary(id, timezone, {
        cancelToken: token,
      });
      return data;
    }
    case "getSupportNotes": {
      const { id, type, page, limit, order, orderBy } = props.params;
      const { data } = await client.getSupportNotes(
        id,
        type,
        page,
        limit,
        order,
        orderBy,
        { cancelToken: token }
      );
      return data;
    }
    case "getSystems": {
      const { id } = props.params;
      const { data } = await client.getSystems(id, { cancelToken: token });
      return data;
    }
    case "getSystemsDataFeed": {
      const { id, start, end } = props.params;
      const { data } = await client.getSystemsDataFeed(id, start, end, true, {
        cancelToken: token,
      });
      return data;
    }
    case "getPopulation": {
      const { id, date } = props.params;
      const { data } = await client.getPopulation(id, date, {
        cancelToken: token,
      });
      return data;
    }
  }
};
