import { createTheme, Theme, darken } from "@material-ui/core/styles";
import { getTintPalette } from "providers/ThemeProvider/ThemeProvider.utilities";

// Emission Control colours
const orange = "#FF8524";
const grey = "#808080";
const blue = "#0093D0";
const green = "#7AC143";
const limeGreen = "#2CAC7F";
const blueGreen = "#0dbab1";
const vividOrange = "#FFAC28";
const brown = "#9D4D24";
const darkBlue = "#005A80";
const red = "#FF4B19";

export const EmissionControlTheme: Theme = createTheme({
  palette: {
    severities: {
      insignificant: "#FFB74D",
      moderate: "#FF6500",
      critical: "#CB0032",
    },
    colors: {
      orange: getTintPalette(orange),
      grey: getTintPalette(grey),
      blue: getTintPalette(blue),
      green: getTintPalette(green),
      limeGreen: getTintPalette(limeGreen),
      vividOrange: getTintPalette(vividOrange),
      brown: getTintPalette(brown),
      darkBlue: getTintPalette(darkBlue),
      red: getTintPalette(red),
    },
    dataKeys: {
      solarGenerated: green,
      gridConsumed: darken(grey, 0.2),
      solarConsumed: vividOrange,
      selfConsumed: vividOrange,
      netConsumed: "#000",
      solarReturned: orange,
      solarFeedIn: orange,
      co2Saved: limeGreen,
      co2Produced: darken(grey, 0.4),
      energyTarget: "#000000",
      expectedGeneration: green,
      batteryStored: blue,
      batteryFeedIn: darken(blue, 0.35),
      gridStored: darkBlue,
      solarStored: blueGreen,
    },
    primary: {
      main: "#334155",
    },
    secondary: {
      main: "#808080",
    },
    error: {
      main: "#CC1C00",
    },
    text: {
      primary: "#334155",
    },
  },
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(","),
    fontWeightRegular: 400,
    fontWeightBold: 900,
  },
});
