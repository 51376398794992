import { Button, lighten } from "@material-ui/core";
import styled from "providers/ThemeProvider";

export const StyledButton = styled(Button)<{
  colorvariant?: "success" | "cancel" | "error";
}>(({ theme, colorvariant }) => {
  // custom color variants
  const varColor =
    colorvariant && colorvariant === "success"
      ? theme.palette.success.main
      : colorvariant === "error"
      ? theme.palette.error.main
      : theme.palette.grey[600];
  const varColorHovered = lighten(varColor, 0.2);
  const varColorActive = lighten(varColor, 0.3);

  return {
    color: theme.palette.common.white,
    fontWeight: 400,
    fontSize: theme.typography.body1.fontSize,
    textTransform: "capitalize",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      ...(colorvariant && {
        backgroundColor: varColorHovered,
      }),
    },
    "&:active": {
      boxShadow: "none",
      ...(colorvariant && {
        backgroundColor: varColorActive,
      }),
    },
    // custom color variants
    ...(colorvariant && {
      backgroundColor: varColor,
    }),
  };
});
