import { Configuration, RemindersApi } from "@solarschools/api-sdk-typescript";
import { ReminderSDK, Reminder, ReminderList } from "./Reminders.types";
import { CancelToken } from "axios";

export const fetchReminderResource = async (
  props: ReminderSDK,
  config: Configuration,
  token: CancelToken
) => {
  const client = new RemindersApi(config);

  switch (props.method) {
    case "getReminder": {
      const { id } = props.params;
      const { data } = await client.get(id, {
        cancelToken: token,
      });
      return data ? (data as Reminder) : undefined;
    }
    case "searchReminder": {
      const {
        page,
        limit,
        orderBy,
        order,
        start,
        end,
        creatorId,
        userId,
        contactId,
        siteId,
        faultId,
        statuses,
      } = props.params;
      const { data } = await client.search(
        page,
        limit,
        orderBy,
        order,
        start,
        end,
        creatorId,
        userId,
        contactId,
        siteId,
        faultId,
        statuses,
        {
          cancelToken: token,
        }
      );
      return data ? (data as ReminderList) : undefined;
    }
    default: {
      throw Error("An invalid Reminders method was encountered");
    }
  }
};
