import { DisplayBoardSDK } from "./DisplayBoard.types";
import { CancelToken } from "axios";
import {
  DisplayBoardApi,
  Configuration,
} from "@solarschools/api-sdk-typescript";

export const fetchDisplayBoardResource = async (
  props: DisplayBoardSDK,
  config: Configuration,
  token: CancelToken
) => {
  const client = new DisplayBoardApi(config);

  switch (props.method) {
    case "get": {
      const { resourceType, resourceId, key } = props.params;
      const { data } = await client.get(resourceType, resourceId, key, {
        cancelToken: token,
      });
      return data;
    }
    case "list": {
      const { resourceType, resourceId } = props.params;
      const { data } = await client.list(resourceType, resourceId, {
        cancelToken: token,
      });
      return data;
    }
    default: {
      throw Error("An invalid Displayboard method was encountered");
    }
  }
};
