import { SystemsApi, Configuration } from "@solarschools/api-sdk-typescript";
import { SystemSDK } from "./Systems.types";
import { CancelToken } from "axios";

export const fetchSystemResource = async (
	props: SystemSDK,
	config: Configuration,
	token: CancelToken
) => {
	const client = new SystemsApi(config);
	switch (props.method) {
		case "getById": {
			const { id } = props.params;
			const { data } = await client.get(id, { cancelToken: token });
			return data;
		}
	}
};
