import { ContractorSDK, Contractor, ContractorList } from "./Contractors.types";
import { CancelToken } from "axios";
import {
  Configuration,
  ContractorsApi,
} from "@solarschools/api-sdk-typescript";

export const fetchContractorResource = async (
  props: ContractorSDK,
  config: Configuration,
  token: CancelToken
) => {
  const client = new ContractorsApi(config);

  switch (props.method) {
    case "getContractor": {
      const { id } = props.params;
      const { data } = await client.get(id, { cancelToken: token });
      return data ? (data as Contractor) : undefined;
    }
    case "listContractors": {
      const { data } = await client.list({ cancelToken: token });
      return data ? (data as ContractorList) : undefined;
    }
    default: {
      throw Error("An invalid Contractors method was encountered");
    }
  }
};
