import { Configuration, FaultsApi } from "@solarschools/api-sdk-typescript";
import { FaultSDK, Fault } from "./Faults.types";
import { CancelToken } from "axios";

export const fetchFaultResource = async (
  props: FaultSDK,
  config: Configuration,
  token: CancelToken
) => {
  const client = new FaultsApi(config);

  switch (props.method) {
    case "getFault": {
      const { id } = props.params;
      const { data } = await client.get(id, { cancelToken: token });
      return data ? (data as Fault) : undefined;
    }
    case "getResourceFaults": {
      const {
        resourceType,
        resourceId,
        page,
        limit,
        minSeverity,
        maxSeverity,
        status,
        type,
        source,
        order,
        orderBy,
      } = props.params;
      const { data } = await client.getResourceFaults(
        resourceType,
        resourceId,
        page,
        limit,
        minSeverity,
        maxSeverity,
        status,
        type,
        source,
        order,
        orderBy,
        { cancelToken: token }
      );
      return data;
    }
    case "getResourceFaultSites": {
      const {
        resourceType,
        resourceId,
        page,
        limit,
        minSeverity,
        maxSeverity,
        status,
        type,
        source,
        order,
      } = props.params;
      const { data } = await client.getResourceFaultSites(
        resourceType,
        resourceId,
        page,
        limit,
        minSeverity,
        maxSeverity,
        status,
        type,
        source,
        order,
        { cancelToken: token }
      );
      return data;
    }
    default: {
      throw Error("An invalid Faults method was encountered");
    }
  }
};
