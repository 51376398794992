import { useContext, useEffect } from "react";
import { UtilityContext } from "./UtilityProvider";
import { UtilityAction } from "./UtilityProvider.types";

export const useUtility = () => {
  const context = useContext(UtilityContext);
  if (context === undefined) {
    throw Error("useUtility must be used within a RouterProvider");
  }
  return context;
};

/* Abstracts a bit of the action receiving away */
export const useUtilityAction = (
  actionName: UtilityAction,
  action: Function
) => {
  const utilityStore = useUtility();

  useEffect(() => {
    if (utilityStore.action === actionName) {
      action();
      utilityStore.setAction(null);
    }
  }, [utilityStore, utilityStore.action, action, actionName]);
};
