import { AuthSDK } from "./Auth.types";
import {
  Configuration,
  AuthenticationApi,
} from "@solarschools/api-sdk-typescript";

export const fetchAuthResource = async (
  props: AuthSDK,
  config: Configuration
) => {
  const client = new AuthenticationApi(config);

  switch (props.method) {
    case "testAccess": {
      const { data } = await client.testAccess();
      return data;
    }
    default: {
      throw Error("An invalid Auth method was encountered");
    }
  }
};
