import { useEffect, createRef } from "react";

export const useFocus = <RefType extends HTMLOrSVGElement>() => {
  const ref = createRef<RefType>();

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  return ref;
};
