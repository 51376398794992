import { AuthTokens } from "providers/StoreProvider";

export const isTokenValid = (jwtToken: string | null = null) => {
  const token = jwtToken ?? null;
  if (token === null) return false;

  try {
    const base64RawPayload = token.split(".")[1];
    const parsedPayload = JSON.parse(window.atob(base64RawPayload));
    const expDate = parsedPayload.exp * 1000;
    return expDate > Date.now();
  } catch (error) {
    console.log(`An error occured parsing JWT string: ${error}`);
    return false;
  }
};

export const isSessionValid = (session: any) => {
  const tokens = (session?.tokens as AuthTokens) ?? null;
  if (tokens) {
    return isTokenValid(tokens.accessToken);
  }
  return false;
};
