import {
  UtilityProps,
  UtilityStore,
  UtilityAction,
} from "./UtilityProvider.types";

export const createUtilityStore = ({
  revalidateContractors,
  reminderStatuses,
  supportNoteTypes,
  faultSeverities,
  googleApiLoaded,
  meterStatuses,
  faultStatuses,
  contractors,
  faultTypes,
}: UtilityProps) => {
  const store: UtilityStore = {
    resourceSites: null,
    setResourceSites(sites) {
      this.resourceSites = sites;
    },
    action: null,
    setAction(action: UtilityAction) {
      this.action = action;
    },
    sideMenuOpen: false,
    setSideMenuOpen(sideMenuOpen: boolean) {
      this.sideMenuOpen = sideMenuOpen;
    },
    isMobile: false,
    setIsMobile(isMobile: boolean) {
      this.isMobile = isMobile;
    },
    loadingThreads: 0,
    addLoadingThreads(amount: number) {
      this.loadingThreads += amount;
    },
    contractors: contractors?.sort((a, b) => a.name.localeCompare(b.name)),
    revalidateContractors,
    reminderStatuses,
    supportNoteTypes,
    googleApiLoaded,
    faultSeverities,
    meterStatuses,
    faultStatuses,
    faultTypes,
    get loading() {
      return !!this.loadingThreads;
    },
  };
  return store;
};
