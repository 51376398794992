import simpleEncryptor, { SimpleEncryptor } from "simple-encryptor";
import { StorageType } from "./Storage.types";
import { fetchEnv } from "utilities/FetchEnv";

export const initEncryptor = (): SimpleEncryptor => {
  const key = fetchEnv({
    key: "ENCRYPT_KEY",
    defaultValue: "solarschoolsengineroom",
  });
  return simpleEncryptor(key);
};

const hasStorage = (type: StorageType, cb: () => any) => {
  let storage: typeof window[StorageType] | undefined;
  try {
    storage = window[type];
    storage.setItem("key", "value");
    storage.removeItem("key");
    return cb();
  } catch (err) {
    return (
      err instanceof DOMException &&
      // everything except Firefox
      (err.code === 22 ||
        // Firefox
        err.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        err.name === "QuotaExceededError" ||
        // Firefox
        err.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
};

export const getItem = <T>(
  key: string,
  storageType: StorageType
): T | undefined => {
  return hasStorage(storageType, () => {
    const encryptedValue = window[storageType].getItem(key);
    return encryptedValue
      ? JSON.parse(initEncryptor().decrypt(encryptedValue) || "")
      : undefined;
  });
};

export const setItem = (key: string, value: any, storageType: StorageType) => {
  hasStorage(storageType, () => {
    const encryptedValue = initEncryptor().encrypt(JSON.stringify(value));
    window[storageType].setItem(key, encryptedValue);
  });
};

export const removeItem = (key: string, storageType: StorageType) => {
  hasStorage(storageType, () => window[storageType].removeItem(key));
};
