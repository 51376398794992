import { FetchEnvProps } from "./FetchEnv.types";

export const fetchEnv = ({
  key,
  defaultValue,
  namespace = "REACT_APP_"
}: FetchEnvProps) => {
  if (!key) {
    throw new Error("FetchEnv: no valid key parameter was passed");
  }

  if (!defaultValue) {
    throw new Error("FetchEnv: no default value parameter was passed");
  }

  const croppedKey = key.replace(namespace, "");

  const envVariable = namespace
    ? process.env[namespace + croppedKey]
    : process.env[croppedKey];

  return envVariable ? envVariable : defaultValue;
};
