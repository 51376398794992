import * as styledComponents from "styled-components";
import { Theme } from "@material-ui/core";

const {
  default: styled,
  css,
  keyframes,
  ThemeProvider: StyledThemeProvider
} = styledComponents as styledComponents.ThemedStyledComponentsModule<Theme>;

export { css, keyframes, StyledThemeProvider };
export default styled;
