import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeAssetContext } from "./ThemeProvider.context";
import React, { FunctionComponent, Fragment } from "react";
import { StyledThemeProvider } from "./ThemeProvider.core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { fetchEnv } from "utilities/FetchEnv";
import DateFnsUtils from "@date-io/date-fns";
import * as themes from "./themes";
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
  Theme,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import {
  ThemeType,
  DomainLogo,
  DomainNavMenuBG,
  DomainLoader,
} from "./ThemeProvider.types";

export const ThemeProvider: FunctionComponent = ({ children }) => {
  const getDomain = () => {
    return fetchEnv({
      key: "THEME",
      defaultValue: "SolarSchools",
    });
  };

  const buildTheme = (): Theme => {
    const domain = getDomain();
    const theme = `${domain}Theme` as ThemeType;
    return responsiveFontSizes(themes[theme]);
  };

  const buildAssets = () => {
    const domain = getDomain();
    const domainLogo = `${domain}Logo` as DomainLogo;
    const domainNavMenuBG = `${domain}NavMenuBG` as DomainNavMenuBG;
    const domainLoader = `${domain}Loader` as DomainLoader;

    return {
      Logo: themes[domainLogo],
      navMenuBG: themes[domainNavMenuBG],
      loader: themes[domainLoader],
    };
  };

  const sessionTheme = buildTheme();
  const sessionAssets = buildAssets();

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={sessionTheme}>
        <StyledThemeProvider theme={{ ...sessionTheme }}>
          <ThemeAssetContext.Provider value={sessionAssets}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Fragment>
                <CssBaseline />
                {children}
              </Fragment>
            </MuiPickersUtilsProvider>
          </ThemeAssetContext.Provider>
        </StyledThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};
