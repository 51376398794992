import useSWR, { ConfigInterface } from "swr";
import { fetchEnv } from "utilities/FetchEnv";
import { useEffect, useMemo, useState } from "react";
import Axios from "middleware/apiClient";
import {
  fetchOrganisationResource,
  fetchDisplayBoardResource,
  fetchContractorResource,
  fetchReminderResource,
  fetchUtilityResource,
  fetchSystemResource,
  fetchSchemaResource,
  fetchMeterResource,
  fetchFaultResource,
  fetchUsersResource,
  fetchSiteResource,
  fetchDataResource,
  fetchAuthResource,
  fetchVirtualMeterResource,
} from "./SDKs";
import {
  isDisplayBoard,
  isOrganisation,
  isContractor,
  isReminder,
  isUtility,
  isSystem,
  isSchema,
  SDKError,
  isMeter,
  isFault,
  isSite,
  isData,
  isAuth,
  isUser,
  isVirtualMeter,
} from "./useSDK.utils";
import {
  ResourceReturnType,
  Configuration,
  SDKReturnType,
  UseSDKProps,
} from "./useSDK.types";

export const useSDK = <T extends ResourceReturnType>(
  { accessToken = "", ...props }: UseSDKProps<T>,
  config: ConfigInterface = {}
): SDKReturnType<T> => {
  const [currentData, setCurrentData] = useState(undefined);
  const jsonProps = JSON.stringify(props);
  const { predicate = true, callback = null } = props;
  const cancelToken = Axios.CancelToken.source();

  const sdkConfig = useMemo(() => {
    const config = new Configuration({
      basePath: fetchEnv({
        key: "API_URI",
        defaultValue: "https://api.solarschools.net",
      }),
      baseOptions: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    });

    return { jsonProps, config };
  }, [accessToken, jsonProps]);

  /**
   * The unique key generated via the requestKey and queryParams properties becomes
   * the url that is fetched against, as well as becoming the unique query-cache key
   * for the data returned from this url. React.Suspense is enabled in order to promote
   * the use of lazy loading components that leverage this library.
   */
  const { data, error, isValidating, mutate, revalidate } = useSWR<
    any,
    SDKError
  >(
    predicate
      ? [`${props.resource}/${props.method}`, sdkConfig.jsonProps]
      : null,
    async (_, props: string) => {
      const parsedProps = JSON.parse(props) as UseSDKProps<T>;

      try {
        if (isSchema(parsedProps)) {
          return await fetchSchemaResource(
            parsedProps,
            sdkConfig.config,
            cancelToken.token
          );
        }

        if (isSite(parsedProps)) {
          return await fetchSiteResource(
            parsedProps,
            sdkConfig.config,
            cancelToken.token
          );
        }

        if (isOrganisation(parsedProps)) {
          return await fetchOrganisationResource(
            parsedProps,
            sdkConfig.config,
            cancelToken.token
          );
        }

        if (isMeter(parsedProps)) {
          return await fetchMeterResource(
            parsedProps,
            sdkConfig.config,
            cancelToken.token
          );
        }

        if (isSystem(parsedProps)) {
          return await fetchSystemResource(
            parsedProps,
            sdkConfig.config,
            cancelToken.token
          );
        }

        if (isFault(parsedProps)) {
          return await fetchFaultResource(
            parsedProps,
            sdkConfig.config,
            cancelToken.token
          );
        }

        if (isData(parsedProps)) {
          return await fetchDataResource(
            parsedProps,
            sdkConfig.config,
            cancelToken.token
          );
        }

        if (isAuth(parsedProps)) {
          return await fetchAuthResource(parsedProps, sdkConfig.config);
        }

        if (isContractor(parsedProps)) {
          return await fetchContractorResource(
            parsedProps,
            sdkConfig.config,
            cancelToken.token
          );
        }

        if (isDisplayBoard(parsedProps)) {
          return await fetchDisplayBoardResource(
            parsedProps,
            sdkConfig.config,
            cancelToken.token
          );
        }

        if (isUser(parsedProps)) {
          return await fetchUsersResource(
            parsedProps,
            sdkConfig.config,
            cancelToken.token
          );
        }

        if (isUtility(parsedProps)) {
          return await fetchUtilityResource(
            parsedProps,
            sdkConfig.config,
            cancelToken.token
          );
        }

        if (isReminder(parsedProps)) {
          return await fetchReminderResource(
            parsedProps,
            sdkConfig.config,
            cancelToken.token
          );
        }

        if (isVirtualMeter(parsedProps)) {
          return await fetchVirtualMeterResource(
            parsedProps,
            sdkConfig.config,
            cancelToken.token
          );
        }

        throw new SDKError("An invalid resource was encountered");
      } catch (error) {
        const defaultMsg = "An unexpected error occured";
        throw new SDKError(error.message ?? defaultMsg, error.response?.status);
      }
    },
    {
      suspense: false,
      revalidateOnFocus: false,
      ...config,
      onSuccess: (data) => setCurrentData(data),
    }
  );

  /**
   * returns previous data if predicate isn't true (because it turns to undefined otherwise)
   * also returns previous data while it's being validated if persist data is true
   */
  const usedData = useMemo(
    () => (predicate ? (props.persist ? currentData : data) : currentData),
    [currentData, data, predicate, props.persist]
  );

  /**
   * If a callback has been supplied, it will fire only if there is
   * a valid response object and predicate. Ensure that any state that
   * is set within a component that uses a callback is aware that the
   * callback, when defined, is a contract based on a successful fetch
   * and not an immediate guarantee.
   */
  useEffect(() => {
    if ((predicate !== null ? predicate : true) && callback && data) {
      callback(data, { revalidate });
    }
  }, [callback, data, revalidate, predicate]);

  return [
    usedData,
    {
      error,
      isValidating,
      mutate,
      revalidate,
      cancelFn: cancelToken.cancel,
    },
  ];
};
