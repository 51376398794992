import { ThemeSpinner } from "components/common/ThemeSpinner";
import { UtilityProvider } from "providers/UtilityProvider";
import { useSessionStore } from "providers/StoreProvider";
import React, { lazy, Suspense } from "react";
import { observer } from "mobx-react-lite";

const AppUnauthenticated = lazy(() => import("./AppUnauthenticated"));
const AppAuthenticated = lazy(() => import("./AppAuthenticated"));

export const App = observer(() => {
  const store = useSessionStore();

  if (store.isAuthenticated && store.defaultResourceType) {
    return (
      <Suspense fallback={<ThemeSpinner isFullscreen />}>
        <UtilityProvider>
          <AppAuthenticated coreResourceType={store.defaultResourceType} />
        </UtilityProvider>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<ThemeSpinner isFullscreen />}>
      <AppUnauthenticated />
    </Suspense>
  );
});
