import React, { FunctionComponent } from "react";

export const EnergyFootprintLogo: FunctionComponent<React.SVGProps<
  SVGSVGElement
>> = (props) => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 753.3 170.1" {...props}>
      <g>
        <path
          d="M151.8,85.4l11.5,1.8c-1.5,4-3.8,7-7,9c-3.2,2.1-7.2,3.1-12,3.1c-7.6,0-13.2-2.3-16.9-7
		c-2.9-3.7-4.3-8.4-4.3-14.1c0-6.8,1.9-12.1,5.7-15.9c3.8-3.8,8.6-5.8,14.4-5.8c6.5,0,11.6,2,15.4,6c3.8,4,5.6,10.2,5.4,18.5h-29
		c0.1,3.2,1,5.7,2.8,7.5c1.8,1.8,4,2.7,6.7,2.7c1.8,0,3.3-0.5,4.6-1.4C150.2,88.9,151.2,87.4,151.8,85.4z M152.4,74.5
		c-0.1-3.1-0.9-5.5-2.6-7.1c-1.6-1.6-3.7-2.4-6-2.4c-2.5,0-4.6,0.9-6.3,2.6c-1.6,1.7-2.5,4.1-2.4,7H152.4z"
        />
        <path
          d="M213.2,98.4h-11.6V77.5c0-4.4-0.2-7.3-0.7-8.6s-1.3-2.3-2.4-3c-1.1-0.7-2.5-1.1-4-1.1c-2,0-3.8,0.5-5.4,1.5
		c-1.6,1-2.7,2.4-3.3,4.1c-0.6,1.7-0.9,4.8-0.9,9.4v18.5h-11.6V57.5h10.8v6c3.8-4.6,8.6-6.9,14.4-6.9c2.6,0,4.9,0.4,7,1.3
		c2.1,0.9,3.7,2,4.8,3.3c1.1,1.3,1.8,2.9,2.3,4.5c0.4,1.7,0.6,4.1,0.6,7.3V98.4z"
        />
        <path
          d="M250.3,85.4l11.5,1.8c-1.5,4-3.8,7-7,9c-3.2,2.1-7.2,3.1-12,3.1c-7.6,0-13.2-2.3-16.9-7
		c-2.9-3.7-4.3-8.4-4.3-14.1c0-6.8,1.9-12.1,5.7-15.9c3.8-3.8,8.6-5.8,14.4-5.8c6.5,0,11.6,2,15.4,6c3.8,4,5.6,10.2,5.4,18.5h-29
		c0.1,3.2,1,5.7,2.8,7.5c1.8,1.8,4,2.7,6.7,2.7c1.8,0,3.3-0.5,4.6-1.4C248.7,88.9,249.7,87.4,250.3,85.4z M251,74.5
		c-0.1-3.1-0.9-5.5-2.6-7.1c-1.6-1.6-3.7-2.4-6-2.4c-2.5,0-4.6,0.9-6.3,2.6c-1.6,1.7-2.5,4.1-2.4,7H251z"
        />
        <path
          d="M283,98.4h-11.6V57.5h10.8v5.8c1.8-2.7,3.5-4.6,5-5.4c1.5-0.9,3.1-1.3,5-1.3c2.6,0,5.2,0.7,7.6,2l-3.6,9.4
		c-2-1.2-3.8-1.8-5.4-1.8c-1.6,0-3,0.4-4.1,1.3c-1.1,0.8-2,2.3-2.7,4.5c-0.6,2.2-1,6.8-1,13.7V98.4z"
        />
        <path
          d="M302.5,101.1l13.2,1.5c0.2,1.4,0.7,2.4,1.5,3c1.1,0.8,2.8,1.2,5.2,1.2c3,0,5.3-0.4,6.8-1.3
		c1-0.6,1.8-1.5,2.3-2.7c0.4-0.9,0.5-2.6,0.5-5v-6c-3.5,4.4-7.8,6.6-13.1,6.6c-5.9,0-10.5-2.3-14-7c-2.7-3.7-4-8.2-4-13.7
		c0-6.9,1.8-12.1,5.3-15.7c3.5-3.6,7.9-5.4,13.2-5.4c5.4,0,9.9,2.2,13.4,6.7v-5.7h10.8v36.7c0,4.8-0.4,8.4-1.3,10.8
		c-0.9,2.4-2,4.3-3.6,5.6c-1.5,1.4-3.6,2.4-6.2,3.2c-2.6,0.8-5.8,1.2-9.7,1.2c-7.4,0-12.7-1.2-15.8-3.6c-3.1-2.4-4.7-5.4-4.7-9
		C302.5,102.1,302.5,101.6,302.5,101.1z M312.8,77.1c0,4.3,0.9,7.5,2.7,9.5c1.8,2,4,3,6.7,3c2.8,0,5.2-1,7.2-3.1
		c2-2.1,2.9-5.1,2.9-9.2c0-4.2-0.9-7.4-2.8-9.4c-1.9-2.1-4.2-3.1-7.1-3.1c-2.8,0-5.1,1-6.9,3C313.7,69.9,312.8,73,312.8,77.1z"
        />
        <path
          d="M349.6,57.5H362l10.5,29l10.2-29h12l-15.5,39.4l-2.8,7.1c-1,2.4-2,4.2-2.9,5.5c-0.9,1.3-2,2.3-3.2,3.1
		s-2.7,1.4-4.4,1.8c-1.7,0.4-3.7,0.7-5.9,0.7c-2.2,0-4.4-0.2-6.6-0.7l-1-8.5c1.8,0.3,3.4,0.5,4.9,0.5c2.7,0,4.7-0.7,6-2.2
		c1.3-1.5,2.3-3.4,3-5.6L349.6,57.5z"
        />
        <path
          d="M401.9,57.5h6.4v-3.1c0-3.4,0.4-6,1.2-7.7c0.8-1.7,2.2-3.1,4.3-4.1c2.1-1.1,4.8-1.6,8-1.6
		c3.3,0,6.5,0.5,9.7,1.4l-1.6,7.5c-1.8-0.4-3.6-0.6-5.3-0.6c-1.7,0-2.9,0.4-3.6,1.1s-1.1,2.1-1.1,4.2v2.9h8.7V66h-8.7v32.4h-11.6V66
		h-6.4V57.5z"
        />
        <path
          d="M432.4,77.4c0-3.6,0.9-7.1,2.8-10.4c1.9-3.4,4.6-5.9,8.1-7.7c3.5-1.8,7.4-2.7,11.6-2.7c6.6,0,12,2,16.3,6
		c4.2,4,6.3,9.1,6.3,15.2c0,6.2-2.1,11.3-6.4,15.4c-4.3,4.1-9.7,6.1-16.1,6.1c-4,0-7.8-0.8-11.5-2.5c-3.6-1.7-6.4-4.2-8.3-7.5
		C433.3,86.1,432.4,82.1,432.4,77.4z M444.2,78c0,4.1,1,7.2,3.1,9.3c2.1,2.2,4.6,3.2,7.6,3.2c3,0,5.6-1.1,7.6-3.2
		c2-2.2,3.1-5.3,3.1-9.4c0-4-1-7.1-3.1-9.2s-4.6-3.2-7.6-3.2c-3,0-5.6,1.1-7.6,3.2C445.3,70.8,444.2,73.9,444.2,78z"
        />
        <path
          d="M483.9,77.4c0-3.6,0.9-7.1,2.8-10.4c1.9-3.4,4.6-5.9,8.1-7.7c3.5-1.8,7.4-2.7,11.6-2.7c6.6,0,12,2,16.3,6
		c4.2,4,6.3,9.1,6.3,15.2c0,6.2-2.1,11.3-6.4,15.4c-4.3,4.1-9.7,6.1-16.1,6.1c-4,0-7.8-0.8-11.5-2.5c-3.6-1.7-6.4-4.2-8.3-7.5
		C484.9,86.1,483.9,82.1,483.9,77.4z M495.8,78c0,4.1,1,7.2,3.1,9.3c2.1,2.2,4.6,3.2,7.6,3.2c3,0,5.6-1.1,7.6-3.2
		c2-2.2,3.1-5.3,3.1-9.4c0-4-1-7.1-3.1-9.2s-4.6-3.2-7.6-3.2c-3,0-5.6,1.1-7.6,3.2C496.8,70.8,495.8,73.9,495.8,78z"
        />
        <path
          d="M558.2,57.5v8.6h-7.9v16.5c0,3.3,0.1,5.3,0.2,5.8c0.2,0.6,0.5,1,1,1.4c0.5,0.4,1.2,0.5,2,0.5
		c1.1,0,2.6-0.3,4.7-1l1,8.4c-2.7,1.1-5.7,1.6-9.2,1.6c-2.1,0-4-0.3-5.6-1c-1.7-0.7-2.9-1.5-3.7-2.5c-0.8-1-1.3-2.4-1.6-4.2
		c-0.2-1.3-0.4-3.8-0.4-7.6V66.1h-5.3v-8.6h5.3v-8.1l11.6-6.3v14.4H558.2z"
        />
        <path
          d="M566,57.5h10.8v6c1.4-2.1,3.3-3.7,5.7-5c2.4-1.3,5-1.9,8-1.9c5.1,0,9.4,1.9,12.9,5.6s5.3,8.9,5.3,15.6
		c0,6.8-1.8,12.1-5.4,15.9c-3.6,3.8-7.9,5.7-13,5.7c-2.4,0-4.6-0.4-6.6-1.3s-4-2.4-6.2-4.6V114H566V57.5z M577.4,77.3
		c0,4.6,1,8,2.9,10.2c2,2.2,4.3,3.3,7.1,3.3c2.7,0,4.9-1,6.7-3c1.8-2,2.7-5.3,2.7-9.9c0-4.3-0.9-7.5-2.8-9.6
		c-1.8-2.1-4.1-3.1-6.8-3.1c-2.8,0-5.2,1-7,3.1C578.3,70.2,577.4,73.3,577.4,77.3z"
        />
        <path
          d="M628.9,98.4h-11.6V57.5h10.8v5.8c1.8-2.7,3.5-4.6,5-5.4c1.5-0.9,3.1-1.3,5-1.3c2.6,0,5.2,0.7,7.6,2l-3.6,9.4
		c-2-1.2-3.8-1.8-5.4-1.8c-1.6,0-3,0.4-4.1,1.3s-2,2.3-2.7,4.5c-0.6,2.2-1,6.8-1,13.7V98.4z"
        />
        <path d="M650.7,52v-10h11.6v10H650.7z M650.7,98.4V57.5h11.6v40.9H650.7z" />
        <path
          d="M714,98.4h-11.6V77.5c0-4.4-0.2-7.3-0.7-8.6c-0.5-1.3-1.3-2.3-2.4-3c-1.1-0.7-2.5-1.1-4-1.1
		c-2,0-3.8,0.5-5.4,1.5c-1.6,1-2.7,2.4-3.3,4.1s-0.9,4.8-0.9,9.4v18.5h-11.6V57.5h10.8v6c3.8-4.6,8.6-6.9,14.4-6.9
		c2.6,0,4.9,0.4,7,1.3c2.1,0.9,3.7,2,4.8,3.3c1.1,1.3,1.8,2.9,2.3,4.5c0.4,1.7,0.6,4.1,0.6,7.3V98.4z"
        />
        <path
          d="M745.9,57.5v8.6H738v16.5c0,3.3,0.1,5.3,0.2,5.8s0.5,1,1,1.4c0.5,0.4,1.2,0.5,2,0.5c1.1,0,2.6-0.3,4.7-1l1,8.4
		c-2.7,1.1-5.7,1.6-9.1,1.6c-2.1,0-4-0.3-5.6-1s-2.9-1.5-3.7-2.5c-0.8-1-1.3-2.4-1.6-4.2c-0.2-1.3-0.4-3.8-0.4-7.6V66.1h-5.3v-8.6
		h5.3v-8.1l11.6-6.3v14.4H745.9z"
        />
      </g>
      <g>
        <path
          d="M190.1,158.5c-2.2,0.9-4.5,1.4-6.8,1.3c-7.2,0-11.9-4.6-11.9-11.4s5-11.9,12.8-11.9c2.1,0,4.1,0.4,6,1.2
		l-0.9,3.1c-1.6-0.8-3.3-1.1-5-1.1c-5.5,0-8.4,3.8-8.4,8.5c-0.3,4.4,3.1,8.1,7.4,8.4c0.3,0,0.6,0,0.9,0c1.8,0,3.7-0.4,5.3-1.1
		L190.1,158.5z"
        />
        <path
          d="M208.4,159.3l-0.4-2.8h-0.1c-1.8,2.2-4.5,3.4-7.3,3.3c-4.8,0-7.3-3.2-7.3-6.4c0-5.4,5.1-8.4,14.4-8.3v-0.5
		c0-1.9-0.5-5.2-5.4-5.2c-2.2,0-4.3,0.6-6.2,1.7l-1-2.7c2.4-1.3,5.1-2,7.9-2c7.3,0,9.1,4.7,9.1,9.2v8.4c0,1.8,0.1,3.6,0.4,5.4
		L208.4,159.3z M207.8,147.9c-4.7-0.1-10.1,0.7-10.1,5c0,2.6,1.9,3.9,4.1,3.9c2.5,0.1,4.8-1.4,5.8-3.7c0.2-0.4,0.2-0.9,0.3-1.3
		V147.9z"
        />
        <path
          d="M219,143.9c0-2.6,0-4.9-0.2-7h3.8l0.1,4.4h0.2c0.9-2.9,3.6-4.8,6.6-4.9c0.4,0,0.8,0,1.2,0.1v3.8
		c-0.5-0.1-1-0.1-1.5-0.1c-3.1,0-5.2,2.2-5.8,5.2c-0.1,0.6-0.2,1.3-0.2,1.9v11.9H219L219,143.9z"
        />
        <path
          d="M234.9,159.3c0.1-1.5,0.2-3.8,0.2-5.8v-27h4.3v14.1h0.1c1.5-2.5,4.3-4.1,8.2-4.1c5.9,0,10.1,4.6,10.1,11.4
		c0,8-5.4,12-10.7,12c-3.5,0-6.2-1.3-8-4.2h-0.1l-0.2,3.7L234.9,159.3z M239.4,150.3c0,0.5,0.1,1,0.2,1.5c0.8,2.9,3.5,4.8,6.5,4.8
		c4.5,0,7.3-3.5,7.3-8.6c0-4.5-2.5-8.3-7.1-8.3c-3.1,0-5.8,2-6.6,5c-0.1,0.5-0.2,1.1-0.2,1.7V150.3z"
        />
        <path
          d="M284.9,148c0,8.3-6.1,11.9-11.9,11.9c-6.5,0-11.5-4.4-11.5-11.5c0-7.5,5.2-11.9,11.9-11.9
		C280.3,136.4,284.9,141.1,284.9,148z M265.9,148.2c0,4.9,3,8.6,7.3,8.6s7.3-3.7,7.3-8.7c0-3.8-2-8.6-7.2-8.6
		C268.1,139.5,265.9,143.9,265.9,148.2L265.9,148.2z"
        />
        <path
          d="M290.3,143c0-2.3,0-4.2-0.2-6.1h3.8l0.3,3.7h0.1c1.7-2.7,4.7-4.3,7.9-4.2c3.3,0,8.4,1.8,8.4,9.5v13.4h-4.3
		v-12.9c0-3.6-1.4-6.6-5.5-6.6c-2.6,0-5,1.7-5.8,4.2c-0.2,0.6-0.3,1.2-0.3,1.9v13.5h-4.4V143z"
        />
        <path d="M340.9,134.7v10.9h11.2v2.8h-11.2v11h-3.2v-11h-11.2v-2.8h11.2v-10.9H340.9z" />
        <path
          d="M370.5,148.9c0.1,5.5,3.9,7.8,8.2,7.8c2.3,0.1,4.5-0.3,6.6-1.2l0.7,2.9c-2.5,1-5.3,1.5-8,1.4
		c-7.4,0-11.8-4.5-11.8-11.3s4.3-12.1,11.2-12.1c7.8,0,9.9,6.4,9.9,10.5c0,0.6-0.1,1.3-0.2,1.9H370.5z M383.3,146
		c0-2.6-1.1-6.6-6-6.6c-4.4,0-6.3,3.8-6.7,6.6H383.3z"
        />
        <path
          d="M392.9,143c0-2.3,0-4.2-0.2-6.1h3.9l0.3,3.7h0.1c1.7-2.7,4.7-4.3,7.9-4.2c3.3,0,8.4,1.8,8.4,9.5v13.4h-4.3
		v-12.9c0-3.6-1.4-6.6-5.5-6.6c-2.6,0-5,1.7-5.8,4.2c-0.2,0.6-0.3,1.2-0.3,1.9v13.5h-4.3L392.9,143z"
        />
        <path
          d="M422.7,148.9c0.1,5.5,3.8,7.8,8.2,7.8c2.3,0.1,4.5-0.3,6.6-1.2l0.7,2.9c-2.5,1-5.3,1.5-8,1.4
		c-7.4,0-11.8-4.5-11.8-11.3s4.3-12.1,11.2-12.1c7.8,0,9.9,6.4,9.9,10.5c0,0.6-0.1,1.3-0.2,1.9H422.7z M435.4,146
		c0-2.6-1.1-6.6-6-6.6c-4.4,0-6.3,3.8-6.7,6.6H435.4z"
        />
        <path
          d="M445,143.9c0-2.6,0-4.9-0.2-7h3.8l0.2,4.4h0.2c0.9-2.9,3.6-4.8,6.6-4.9c0.4,0,0.8,0,1.2,0.1v3.8
		c-0.5-0.1-1-0.1-1.5-0.1c-3.1,0-5.2,2.2-5.8,5.2c-0.1,0.6-0.2,1.3-0.2,1.9v11.9H445V143.9z"
        />
        <path
          d="M481.7,137c-0.1,1.6-0.2,3.4-0.2,6.2v13c0,5.1-1.1,8.3-3.4,10.2s-5.7,2.7-8.7,2.7c-2.8,0.1-5.5-0.6-8-1.8
		l1.1-3.1c2.1,1.2,4.6,1.8,7,1.8c4.4,0,7.7-2.2,7.7-7.8v-2.5h-0.1c-1.7,2.5-4.6,3.9-7.6,3.8c-5.9,0-10.2-4.7-10.2-10.9
		c0-7.6,5.3-11.9,10.8-11.9c4.2,0,6.4,2,7.5,3.9h0.1l0.2-3.4L481.7,137z M477.3,145.8c0-0.6-0.1-1.3-0.3-1.8
		c-0.8-2.6-3.3-4.4-6.1-4.3c-4.1,0-7.1,3.3-7.1,8.5c0,4.4,2.4,8,7.1,8c2.7,0,5.1-1.6,6-4.2c0.3-0.7,0.4-1.5,0.4-2.2V145.8z"
        />
        <path
          d="M490.3,137l5.2,13.2c0.5,1.5,1.1,3.2,1.5,4.6h0.1c0.5-1.3,0.9-3,1.5-4.7l4.8-13.1h4.6l-6.5,16
		c-3.1,7.7-5.2,11.6-8.2,14c-1.5,1.3-3.4,2.2-5.3,2.6l-1.1-3.4c1.4-0.4,2.7-1.1,3.8-2c1.6-1.2,2.8-2.8,3.7-4.5
		c0.2-0.3,0.3-0.6,0.3-1c0-0.4-0.1-0.7-0.3-1.1l-8.8-20.6H490.3z"
        />
        <path
          d="M522.5,143c0-2.3,0-4.2-0.2-6.1h3.8l0.2,3.6h0.1c1.6-2.7,4.4-4.2,7.5-4.1c3-0.1,5.7,1.7,6.8,4.5h0.1
		c0.7-1.2,1.6-2.1,2.7-2.9c1.5-1.1,3.4-1.6,5.3-1.6c3.2,0,7.9,1.9,7.9,9.7v13.2h-4.3v-12.7c0-4.3-1.7-6.9-5.2-6.9
		c-2.3,0-4.4,1.5-5.1,3.7c-0.2,0.6-0.3,1.3-0.3,2v13.8h-4.3v-13.4c0-3.6-1.7-6.2-5-6.2c-2.5,0.1-4.6,1.7-5.4,4.1
		c-0.2,0.6-0.4,1.3-0.3,2v13.5h-4.3V143z"
        />
        <path
          d="M585.3,148c0,8.3-6.1,11.9-11.9,11.9c-6.5,0-11.5-4.4-11.5-11.5c0-7.5,5.2-11.9,11.8-11.9
		C580.7,136.4,585.3,141.1,585.3,148z M566.3,148.2c0,4.9,3,8.6,7.3,8.6s7.3-3.7,7.3-8.7c0-3.8-2-8.6-7.2-8.6
		S566.3,143.9,566.3,148.2L566.3,148.2z"
        />
        <path
          d="M590.8,143c0-2.3,0-4.2-0.2-6.1h3.9l0.2,3.7h0.1c1.7-2.7,4.7-4.3,7.9-4.2c3.3,0,8.4,1.8,8.4,9.5v13.4h-4.3
		v-12.9c0-3.6-1.4-6.6-5.5-6.6c-2.6,0-5,1.7-5.8,4.2c-0.2,0.6-0.3,1.2-0.3,1.9v13.5h-4.3L590.8,143z"
        />
        <path
          d="M623,130.7c-0.1,1.5-1.4,2.6-2.9,2.5c-1.3-0.1-2.3-1.2-2.5-2.5c0-1.4,1.2-2.6,2.6-2.5c0,0,0.1,0,0.1,0
		c1.4-0.1,2.6,0.9,2.7,2.3C623,130.5,623,130.6,623,130.7z M618.2,159.3V137h4.3v22.4H618.2z"
        />
        <path
          d="M635,130.5v6.4h6.2v3.1H635v12.1c0,2.8,0.8,4.3,3.3,4.3c0.8,0,1.7-0.1,2.5-0.3l0.2,3.1
		c-1.2,0.4-2.5,0.6-3.8,0.5c-1.7,0.1-3.4-0.5-4.7-1.7c-1.2-1.2-1.7-3.2-1.7-5.8v-12.2H627V137h3.7v-5.4L635,130.5z"
        />
        <path
          d="M667.7,148c0,8.3-6.1,11.9-11.9,11.9c-6.5,0-11.5-4.4-11.5-11.5c0-7.5,5.2-11.9,11.8-11.9
		C663.1,136.4,667.7,141.1,667.7,148z M648.7,148.2c0,4.9,3,8.6,7.3,8.6s7.3-3.7,7.3-8.7c0-3.8-2-8.6-7.2-8.6
		S648.7,143.9,648.7,148.2L648.7,148.2z"
        />
        <path
          d="M673.2,143.9c0-2.6,0-4.9-0.2-7h3.8l0.2,4.4h0.2c0.9-2.9,3.6-4.8,6.6-4.9c0.4,0,0.8,0,1.2,0.1v3.8
		c-0.5-0.1-1-0.1-1.5-0.1c-3.1,0-5.2,2.2-5.8,5.2c-0.1,0.6-0.2,1.3-0.2,1.9v11.9h-4.3V143.9z"
        />
        <path
          d="M694.2,130.7c-0.1,1.5-1.4,2.6-2.9,2.5c-1.3-0.1-2.3-1.2-2.5-2.5c0-1.4,1.2-2.6,2.6-2.5c0,0,0.1,0,0.1,0
		c1.4-0.1,2.6,1,2.7,2.3C694.2,130.5,694.2,130.6,694.2,130.7z M689.3,159.3V137h4.3v22.4H689.3z"
        />
        <path
          d="M700.9,143c0-2.3,0-4.2-0.2-6.1h3.8l0.3,3.7h0.1c1.7-2.7,4.7-4.3,7.9-4.2c3.3,0,8.5,1.8,8.5,9.5v13.4h-4.3
		v-12.9c0-3.6-1.4-6.6-5.5-6.6c-2.6,0-5,1.7-5.8,4.2c-0.2,0.6-0.3,1.2-0.3,1.9v13.5h-4.3L700.9,143z"
        />
        <path
          d="M748.9,137c-0.1,1.6-0.2,3.4-0.2,6.2v13c0,5.1-1.1,8.3-3.4,10.2s-5.7,2.7-8.7,2.7c-2.8,0.1-5.5-0.6-8-1.8
		l1.1-3.1c2.1,1.2,4.6,1.8,7,1.8c4.4,0,7.7-2.2,7.7-7.8v-2.5h-0.1c-1.7,2.5-4.6,3.9-7.6,3.8c-5.9,0-10.2-4.7-10.2-10.9
		c0-7.6,5.3-11.9,10.8-11.9c4.2,0,6.4,2,7.5,3.9h0.1l0.2-3.4L748.9,137z M744.4,145.8c0-0.6-0.1-1.3-0.2-1.8
		c-0.8-2.6-3.3-4.4-6.1-4.3c-4.2,0-7.1,3.3-7.1,8.5c0,4.4,2.4,8,7.1,8c2.7,0,5.1-1.6,6-4.2c0.2-0.7,0.3-1.4,0.3-2.2L744.4,145.8z"
        />
      </g>
      <g>
        <path d="M80.3,14C80.8,6.6,77.2,0.4,72.2,0s-9.6,5.4-10.1,12.7s3,13.6,8.1,13.9S79.7,21.3,80.3,14" />
        <path d="M54.9,17.8c-1.7-6.2-6.4-10.4-10.6-9.4s-6.3,6.8-4.6,13s6.4,10.4,10.6,9.4S56.5,24,54.9,17.8" />
        <path d="M34.3,28.1c-3.3-4.7-8.6-6.9-11.8-4.9s-3.1,7.4,0.2,12.1s8.6,6.9,11.8,4.9S37.6,32.8,34.3,28.1" />
        <path d="M19.3,42.4c-4.1-3-9-3.5-11.1-1.1s-0.4,6.8,3.6,9.8s9,3.5,11.1,1.1S23.3,45.4,19.3,42.4" />
        <path d="M10.9,58.5c-4.2-2.2-8.9-2-10.4,0.5s0.7,6.4,4.9,8.6s8.9,2,10.4-0.5S15.2,60.8,10.9,58.5" />
        <path
          d="M82.5,42.5c-7.7-9.6-18.9-10.6-29.6-6.8c-19,38.4,17.3,74.1,17.3,74.1C36.2,79,38.1,52.5,44.5,39.6
		C35.7,44.9,28,52.9,23.9,61.5c-7.5,17.6-0.7,42.2,19,58.3c32.7,27.2,79.3,4.8,79.3,4.8C69.2,110.6,98.8,62.8,82.5,42.5"
        />
        <path
          d="M98.2,161.6c25.4,5.6,36-11.7,36-11.7C127.5,175.5,98.2,161.6,98.2,161.6 M139.4,138.7
		c-2.7-5.7-7.9-8.3-14.2-8.1c-13.1,0.8-50.3,22.7-90.1-10.7c85.2,87.7,106.2,35.6,106,26.6C141.2,143.8,140.6,141.2,139.4,138.7"
        />
      </g>
    </svg>
  );
};
