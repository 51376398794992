import { setItem, removeItem, getItem } from "utilities/Storage";
import { isTokenValid } from "utilities";
import {
  SessionStore,
  DashSettings,
  AuthTokens,
  Session,
} from "./StoreProvider.types";

export const isSession = (session: any): session is Session => {
  return (session as Session).user !== undefined;
};

export const hydrateStore = () => {
  const session = getItem<Session>("ER_SESSION", "localStorage");
  const accessToken = session?.tokens?.accessToken ?? null;
  if (session && accessToken && isTokenValid(accessToken)) {
    return session;
  }
  return defaultSession;
};

export const defaultSession: Session = {
  dashSettings: {
    mapView: "Solar Generation",
    timePeriod: "today",
    resourceId: 0,
    resourceType: null,
  },
  defaultResource: {
    resourceId: 0,
    resourceType: null,
  },
  email: "",
  isLocked: false,
  permissions: [],
  tokens: {
    accessToken: null,
    idToken: null,
    refreshToken: null,
  },
  user: null,
};

export const createStore = (session: Session) => {
  const store: SessionStore = {
    session,

    setSession(session: Session) {
      this.session = session;
      setItem("ER_SESSION", this.session, "localStorage");
    },

    clearSession() {
      this.session = defaultSession;
      removeItem("ER_SESSION", "localStorage");
    },

    setSettings(settings: DashSettings) {
      this.session.dashSettings = { ...this.session.dashSettings, ...settings };
      setItem("ER_SESSION", this.session, "localStorage");
    },

    lockSession() {
      this.session.isLocked = true;
      setItem("ER_SESSION", this.session, "localStorage");
      window.location.reload();
    },

    unlockSession() {
      this.session.isLocked = false;
      setItem("ER_SESSION", this.session, "localStorage");
    },

    refreshTokens(tokens: AuthTokens) {
      this.session.tokens = tokens;
      setItem("ER_SESSION", this.session, "localStorage");
    },

    get isLocked() {
      return this.session.isLocked;
    },

    get accessToken() {
      return this.session.tokens.accessToken;
    },

    get defaultResourceId() {
      return this.session.defaultResource.resourceId;
    },

    get defaultResourceType() {
      return this.session.defaultResource.resourceType;
    },

    get isAdmin() {
      return this.session.permissions.includes(
        "admin::*::admin::permissions::write"
      ); //TODO replace with a more accurate representation (the old all::all stopped working)
    },

    get settings() {
      return this.session.dashSettings;
    },

    get isAuthenticated() {
      return isTokenValid(this.session.tokens.accessToken);
    },

    get userFullName() {
      const firstName = this.session.user?.firstName;
      const surname = this.session.user?.surname;
      return firstName && surname ? `${firstName} ${surname}` : "Guest";
    },

    get userId() {
      return this.session.user?.id;
    },
  };
  return store;
};
