import { AuthenticationApi } from "@solarschools/api-sdk-typescript";
import axios from "axios";
import { AuthTokens, Session } from "providers/StoreProvider";
import { getConfig } from "utilities/SDKConfig";
import { getItem, setItem } from "utilities/Storage";

export const refreshToken = async () => {
  const session: Session | undefined = getItem("ER_SESSION", "localStorage");
  const username = session?.email;
  const refreshToken = session?.tokens?.refreshToken;
  const accessToken = session?.tokens?.accessToken;

  // refresh the session
  if (session && username && refreshToken && accessToken) {
    const config = getConfig(accessToken);
    const client = new AuthenticationApi(config);
    const { data } = await client.refreshSession("false", {
      username,
      refreshToken,
    });

    // update tokens
    const tokens = data as AuthTokens;
    session.tokens = tokens;
    setItem("ER_SESSION", session, "localStorage");
  }

  return accessToken;
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      (error.response.status === 403 || error.response.status === 401) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const accessToken = await refreshToken();
      if (accessToken) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + accessToken;
      }
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default axios;
