import { Configuration, MetersApi } from "@solarschools/api-sdk-typescript";
import { CancelToken } from "axios";
import {
  WattWatcherShortEnergy,
  MeterUtilityList,
  MeterTypeList,
  MeterSDK,
  ChannelsDataFeed,
  Meter,
} from "./Meters.types";

export const fetchMeterResource = async (
  props: MeterSDK,
  config: Configuration,
  token: CancelToken
) => {
  const client = new MetersApi(config);
  switch (props.method) {
    case "getLatestShortEnergy": {
      const { id } = props.params;
      const { data } = await client.getLatestShortEnergy(id, {
        cancelToken: token,
      });
      return data ? (data as WattWatcherShortEnergy) : undefined;
    }
    case "getShortEnergy": {
      const { id, start, end } = props.params;
      const { data } = await client.getShortEnergy(id, start, end, {
        cancelToken: token,
      });
      return data ? (data as any) : undefined;
    }
    case "getMeterTypes": {
      const { data } = await client.getMeterTypes({ cancelToken: token });
      return data ? (data as MeterTypeList) : undefined;
    }
    case "getMeter": {
      const { id, attachChannels } = props.params;
      const { data } = await client.get(id, attachChannels, {
        cancelToken: token,
      });
      return data ? (data as Meter) : undefined;
    }
    case "getChannelsDataFeed": {
      const { id, start, end, timeContext, fields } = props.params;
      const { data } = await client.getChannelsDataFeed(
        id,
        start,
        end,
        timeContext,
        fields,
        { cancelToken: token }
      );
      return data ? (data as ChannelsDataFeed) : undefined;
    }
    case "listUtilities": {
      const { data } = await client.listUtilities({ cancelToken: token });
      return data ? (data as MeterUtilityList) : undefined;
    }
    case "getWattWatchersDevice": {
      const { deviceId } = props.params;
      const { data } = await client.getWattwatchersDevice(deviceId, {
        cancelToken: token,
      });
      return data ? (data as any) : undefined;
    }
    default: {
      throw Error("An invalid Meters method was encountered");
    }
  }
};
