import { useSessionStore } from "providers/StoreProvider";
import { useLocation } from "react-router-dom";
import { useObserver } from "mobx-react-lite";

const resourceTypes = ["sites", "schemas", "organisations"] as const;

export const useUrlState = <T extends typeof resourceTypes[number]>() => {
  const store = useSessionStore();
  const { pathname } = useLocation();
  const [type, id] = pathname.split("/").slice(1);

  return useObserver(() => {
    const resourceId = (id && parseInt(id, 10)) || store.settings.resourceId;
    const resourceType = resourceTypes.includes(type as any)
      ? (type as T)
      : (store.settings.resourceType as T);

    return { resourceId, resourceType } as const;
  });
};
