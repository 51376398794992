import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { UtilityProps, UtilityStore } from "./UtilityProvider.types";
import { ThemeSpinner } from "components/common/ThemeSpinner";
import { createUtilityStore } from "./UtilityProvider.utils";
import { useSessionStore } from "providers/StoreProvider";
import { ContractorList, XlatValues } from "hooks/useSDK";
import { useLoadScript } from "@react-google-maps/api";
import { useLocalStore } from "mobx-react-lite";
import React, { createContext, useEffect } from "react";
import { fetchEnv } from "utilities";
import { useSDK } from "hooks";

const googleLibraries: Libraries = ["places"];

export const UtilityContext = createContext<UtilityStore | undefined>(
  undefined
);

export const UtilityStoreBuilder = (props: UtilityProps) => {
  const store = useLocalStore(() => createUtilityStore(props));

  // update the contractors if there's a new one
  useEffect(() => {
    if (props?.contractors?.length !== store?.contractors?.length) {
      store.contractors = props.contractors;
    }
  }, [props, store]);

  return (
    <UtilityContext.Provider value={store}>
      {props.children}
    </UtilityContext.Provider>
  );
};

export const UtilityProvider = ({ children }) => {
  const sessionStore = useSessionStore();

  const [meterStatuses, { error: meterStatusesError }] = useSDK<XlatValues>({
    accessToken: sessionStore.accessToken,
    resource: "utility",
    method: "listXlatValues",
    params: {
      key: "meter-status",
    },
  });

  const [reminderStatuses, { error: reminderStatusesError }] =
    useSDK<XlatValues>({
      accessToken: sessionStore.accessToken,
      resource: "utility",
      method: "listXlatValues",
      params: {
        key: "reminder-status",
      },
    });

  const [faultStatuses, { error: faultStatusesError }] = useSDK<XlatValues>({
    accessToken: sessionStore.accessToken,
    resource: "utility",
    method: "listXlatValues",
    params: {
      key: "fault-status",
    },
  });

  const [supportNoteTypes, { error: supportNoteTypesError }] =
    useSDK<XlatValues>({
      accessToken: sessionStore.accessToken,
      resource: "utility",
      method: "listXlatValues",
      params: {
        key: "support-note-types",
      },
    });

  const [faultTypes, { error: faultTypesError }] = useSDK<XlatValues>({
    accessToken: sessionStore.accessToken,
    resource: "utility",
    method: "listXlatValues",
    params: {
      key: "fault-types",
    },
  });

  const [faultSeverities, { error: faultSeveritiesError }] = useSDK<XlatValues>(
    {
      accessToken: sessionStore.accessToken,
      resource: "utility",
      method: "listXlatValues",
      params: {
        key: "fault-severity",
      },
    }
  );

  const [
    contractors,
    { error: contractorsError, revalidate: revalidateContractors },
  ] = useSDK<ContractorList>({
    accessToken: sessionStore.accessToken,
    resource: "contractors",
    method: "listContractors",
  });

  /**
   * Load Google Maps API
   */
  const { isLoaded, loadError } = useLoadScript({
    id: "sites-map",
    googleMapsApiKey: fetchEnv({
      key: "GOOGLE_MAPS_API_KEY",
      defaultValue: "default",
    }),
    libraries: googleLibraries,
  });

  /* show spinner if fetching -- but still continue if fetch fails
    (removes single point of failure) */
  if (
    (!meterStatuses && !meterStatusesError) ||
    (!reminderStatuses && !reminderStatusesError) ||
    (!faultStatuses && !faultStatusesError) ||
    (!supportNoteTypes && !supportNoteTypesError) ||
    (!faultSeverities && !faultSeveritiesError) ||
    (!contractors && !contractorsError) ||
    (!faultTypes && !faultTypesError) ||
    (!isLoaded && !loadError)
  ) {
    return <ThemeSpinner isFullscreen />;
  }

  return (
    <UtilityStoreBuilder
      revalidateContractors={revalidateContractors}
      reminderStatuses={reminderStatuses}
      supportNoteTypes={supportNoteTypes}
      faultSeverities={faultSeverities}
      meterStatuses={meterStatuses}
      faultStatuses={faultStatuses}
      googleApiLoaded={isLoaded}
      contractors={contractors}
      faultTypes={faultTypes}
    >
      {children}
    </UtilityStoreBuilder>
  );
};
